import {Component, 
  createRef, 
  KeyboardEvent, 
  // useState, 
  // useEffect
} from "react"



interface BaseStates {
  showChild: boolean;
}
class ImagesViewerComponent extends Component <any, {}>{
  // textInput = new HTMLDivElement();
  node:any;
  constructor(props: any) {
    super(props);
    // this.textInput = "";
    this.node = createRef();
  }
  // clickRecord(){
  //   console.log("recorded click")
  // }

  keyHandler = (e:KeyboardEvent) => {
    if((e.key === "ArrowLeft") || (e.key === "ArrowUp")){
      // console.log("arrowLeft")
      this.props.prevImage(e);
      e.preventDefault();
    }else if((e.key === "ArrowRight") || (e.key === "ArrowDown")){
      // console.log("arrowLeft")
      this.props.nextImage(e);
      e.preventDefault();
    }
    else if (((e.key === 'Escape') || (e.key === "q")) || (e.key === "Q")) {
      // escape
      // console.log("esc")
      this.props.onClose(e);
      e.preventDefault();
    }
  }
  componentDidMount() {
    this.node.current.focus();
  }
  render(){
    return (
    <div style={{
      position:"fixed",
      backgroundColor:"#55555555",
      zIndex:2000,
      width: "100%",
      height:"100%",
      top:0,
      left:0,
      display:"flex",
    }} onClick={this.props.onClose} 
      tabIndex={0} 
      onKeyDown={this.keyHandler}
      ref={this.node}
      >
      <div className="arrow leftArrow nonDrag" style={{
        alignSelf:"center",
      }} onClick={this.props.prevImage}/>
        <img style={{
          width:"80%", 
          height:"80%", 
          objectFit:"contain",
          alignSelf:"center",
          backgroundColor:"#282c34",
          }} src={this.props.imagePath} onScroll={this.props.scrollHandler} onClick={(e:any) => {e.stopPropagation()}}
          alt={this.props.alt}
          />
      <div className="arrow rightArrow nonDrag" style={{
        alignSelf:"center",
      }} onClick={this.props.nextImage} onKeyPress={(e:any)=>{console.log("key pressed")}}/>
      <div onClick={this.props.onClose} className="close nonDrag" draggable="false" onDragStart={()=>{}}/>
    </div>);
  }
}
class Image extends Component<any, {}>{
  // constructor(props:any) {
  //   super(props);
  // }
  // keyDown = (e:KeyboardEvent)=>{
  //   if(e.key === "Enter"){
  //     // console.log("arrowLeft")
  //     this.props.openChild();
  //     e.preventDefault();
  //   }
  // }
  render(){
    return (
    <>
      <br/>
        <img src={this.props.imagePath} alt="V-USB schematics for using USB with an ATtiny" {...this.props} tabIndex={0}/>
      <br/>
    </>
    )
  };
}
class ImagesViewer<S extends BaseStates> extends Component<any, S> {
  
  initialImage = "";
  currentImage : string|null= "";
  images: Array<string|null> = [];
  static ivIndex: number = 1; // index starts at 1 because the image in the title is index 0
  index = -1;
  currentIndex = -1;
  // showCaption = false;
  closeChild = (e:any) => {
    e.stopPropagation();
    this.setState({
      showChild: false
    });    
  };
  openChild = () =>{
    if(!this.images.length){
      this.scanImages();
    }
    // if(this.index = -1){
    //   this.getIndex();
    // }
    this.currentIndex = this.index;
    this.currentImage = this.initialImage;
    this.setState({
      showChild: true
    });
    sessionStorage.setItem("scrollPosition", (window.scrollY).toString());
  }
  keyDown = (e:KeyboardEvent)=>{
    if(e.key === "Enter"){
      this.openChild();
      e.preventDefault();
    }
  }
  // getIndex(){
  //   console.log(this)
  // }
  getInitialState(): S {
    return { showChild: false } as S;
  };
  componentDidUpdate(){
    if(!this.state.showChild){
      const scrollPosition = sessionStorage.getItem("scrollPosition");
      if(scrollPosition){ 
        for(let i=10; i<100; i+=10){
          // well, this is not pretty
          // but I can't find how to it without an image flickering
          // if I set the timeout too short, sometimes it's not activated, 
          // if I set it too long, there is flickering
          setTimeout(function () {
            window.scrollTo(0, parseInt(scrollPosition));
            // console.log("scrolled");
          },i);
        }
        // window.scrollTo(0, parseInt(scrollPosition));
        // console.log("ImagesViewer scrolling to ", scrollPosition);
        sessionStorage.removeItem("scrollPosition");
      }
    }else{
      sessionStorage.setItem("scrollPosition", (window.scrollY).toString());
    }
  }
  nextImage = (e:any) => {
    e.stopPropagation();
    console.log("next Image ?");
    console.log("current image", this.currentImage);
    // let index = this.images.findIndex(el=>el===this.currentImage);
    console.log("this.currentIndex : ", this.currentIndex);
    this.currentIndex = (this.currentIndex +1) % (this.images.length);
    this.currentImage = this.images[this.currentIndex];
    console.log("new image", this.currentImage);
    this.setState({})
  };

  prevImage = (e:any) => {
    e.stopPropagation();
    // console.log("next Image ?");
    // console.log("current image", this.currentImage);
    // let index = this.images.findIndex(el=>el===this.currentImage);
    // console.log("index : ", index)
    this.currentIndex = ((this.currentIndex-1)>=0 ? this.currentIndex-1 : this.images.length -1 );
    // console.log("new index: ", index);
    this.currentImage = this.images[this.currentIndex];
    // console.log("new image", this.currentImage);
    this.setState({})
  };

  scrollHandler = (e:any) =>{
    // this doesn't work
    console.log("recorded scroll")
  };

  scanImages = ()=>{
    const imagesElements : Array<string|null> = Array.from(
      document.querySelectorAll("img")
    ).map((e)=>{
      let s = e.src?.match(/\/static.*$/);
      return s?s[0]:null});
    const titleImage = document.querySelector("#title")?.parentElement?.parentElement?.style.backgroundImage.split('"')[1];
    console.log("title image : ", titleImage)
    imagesElements.unshift(titleImage as string);
    console.log(imagesElements);
    console.log(this.images);
    this.images = imagesElements;
  };

  // withMyHook(Component:any) {
  //   return function WrappedComponent(Component.props:any) {
  //     const myHookValue = useImages();
  //     return <Component {...props} myHookValue={myHookValue} />;
  //   }
  // }

  constructor(props:any) {
    super(props);
    this.initialImage = props.imagePath;
    this.currentImage = props.imagePath;
    // this.images = props.images;
    // this.test();
    // props.onClick = this.openChild;
    // this.props.children = this;
    this.index = ImagesViewer.ivIndex;
    // if(this.props.)
    // if(this.props.caption)
    //   this.showCaption=true;

    ImagesViewer.ivIndex += 1;

    // console.log("index = ", this.index);

    this.state = this.getInitialState();
    this.setState ({
      showChild: false
    });
    // this.nextImage = this.nextImage.bind(this);
  }
  
  render() {
    console.log(this.props)
    return (
      <div>
        <Image {...this.props} onClick={this.openChild} onKeyDown={this.keyDown}/>
        {(this.props.showCaption||this.props.caption) && <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
            {this.props.caption?this.props.caption : this.props.alt}
          </div>} 
        {this.state.showChild && <ImagesViewerComponent imagePath={this.currentImage} onClose={this.closeChild} prevImage={this.prevImage} nextImage={this.nextImage} scrollHandler={this.scrollHandler}/>}
      </div>
    );
  }

  // imageViewerVisible: boolean;

  
}

export{
  ImagesViewer,
}