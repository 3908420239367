import {Component} from "react"

class SectionTitle extends Component <any, {}>{
  static n0 = 0;
  static n1 = 0;
  static n2 = 0;

  render(){
    const {level, ...filteredProps} = this.props;
    if(this.props.level === 0){
      SectionTitle.n0 += 1;
      SectionTitle.n1 = 0;
      SectionTitle.n2 = 0;
       
      return (
        <h2 {...filteredProps}>{SectionTitle.n0 + ". " + this.props.children}</h2>
      )
    }else if(this.props.level === 1){
      SectionTitle.n1 += 1;
      SectionTitle.n2 = 0;
      return (
        <h3 {...filteredProps}>{SectionTitle.n0 + "." + SectionTitle.n1 + ". " + this.props.children}</h3>
      )
    }else if(this.props.level === 2){
      SectionTitle.n2 += 1;
      return (
        <h4 {...filteredProps}>{SectionTitle.n0 + "." + SectionTitle.n1 + "." + SectionTitle.n2 + ". " + this.props.children}</h4>
      )
    }
    
  }
}

class H2 extends Component <any, {}>{
  render(){
    return (
      <SectionTitle level={0} {...this.props}>{this.props.children}</SectionTitle>
    );
  }
}
class H3 extends Component <any, {}>{
  render(){
    return (
      <SectionTitle level={1} {...this.props}>{this.props.children}</SectionTitle>
    );
  }
}
class H4 extends Component <any, {}>{
  render(){
    return (
      <SectionTitle level={2} {...this.props}>{this.props.children}</SectionTitle>
    );
  }
}

export {
  H2,
  H3,
  H4
}