import React from "react";
// import HeaderInterface from "./HeaderInterface";


// import { AiOutlineUser } from "react-icons/ai";
import {
  // Container,
  Nav,
  Navbar,
  NavbarBrand,
  // NavbarText,
  // NavItem,
  NavLink,
} from "reactstrap";

import BurgerMenu from "../BurgerMenu";

// const [expanded, setExpanded] = useState(false);



var linksList = [
  {displayName:"News", link: "/news", cName:"link-nostyle"},
  {displayName:"About", link: "/about", cName:"link-nostyle"},
  {displayName:"Articles", link: "/articles", cName:"link-nostyle"},
]


class Header extends React.Component <{}>{
  // constructor (props: HeaderInterface){
  //   super(props);
  // }
  render() {
    // var isActive = this.context.router.route.location.pathname;
    const pathname = window.location.pathname
    
    for (let index = 0; index < linksList.length; index++) {
      const elt = linksList[index];
      if (elt.link === pathname){
        elt.cName = "link-header-uline";
      }
      
    }
    return (
      <div className="headerNavbar">
        {/* <div className="item">Item1</div>
        <div className="item">Item2</div>
        <div className="item">Item3</div> */}
        <Navbar>
          
          <NavbarBrand href="/" className="link-nostyle">Skpzk sandbox</NavbarBrand>
          <Nav className="mr-auto"  navbar>
            {
              linksList.map(item=>
                <NavLink href={item.link} className={item.cName}>{item.displayName}</NavLink>
              )
            }
            
              {/* <NavLink href="/news" className="link-nostyle">News</NavLink>

              <NavLink href="/about" className="link-nostyle">
                About
              </NavLink>

              <NavLink href="/articles" className="link-nostyle">
                Articles
              </NavLink> */}
            
          </Nav>
          {/* <NavbarText>
            <div>
              Some text
            </div>
          </NavbarText> */}
        </Navbar>
        <div className="burger-menu">
          <div className="spacer"></div>
          <div className="menu"><BurgerMenu/></div>
        </div>
      </div>
    );
  }
};
export default Header;