import { slide as Menu } from 'react-burger-menu'
import "./BurgerMenu.css"
// import React from 'react';


// class Example extends React.Component {
//   showSettings (event) {
//     event.preventDefault();
//     .
//     .
//     .
//   }

//   render () {
//     // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
//     return (
//       <Menu>
//         <a id="home" className="menu-item" href="/">Home</a>
//         <a id="about" className="menu-item" href="/about">About</a>
//         <a id="contact" className="menu-item" href="/contact">Contact</a>
//         <a onClick={ this.showSettings } className="menu-item--small" href="">Settings</a>
//       </Menu>
//     );
//   }
// }

const BurgerMenu = ()=>{
  return (
    <Menu right >
      <a id="home" className="menu-item link-nostyle" href="/">Home</a>
      <a id="about" className="menu-item link-nostyle" href="/about">About</a>
      <a id="news" className="menu-item link-nostyle" href="/news">News</a>
      <a className="menu-item link-nostyle" href="/articles">Articles</a>
      {/* <a onClick={ this.showSettings } className="menu-item--small" href="">Settings</a> */}
    </Menu>
  );
};

export default BurgerMenu;