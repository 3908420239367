import SyntaxHighlighter from 'react-syntax-highlighter';
import {vs2015} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {Component, createElement, 
  // createRef, 
  // KeyboardEvent, 
  // useState, 
  // useEffect, 
  // ReactNode
} from "react"

// images
// import sharm_gui_v2 from "./img/SharmGuiv2.png"

import {CollapsibleTableOfContents} from "../../../../components/Toc"
import ScrollToTop from '../../../../components/ScrollToTop';
import {CodeBlock, InlineCode, InlineCommand} from "../../../../components/Code"
// import bibtex from "bibtex";
import bibtexParse from "bibtex-parser-js";
// import Latex from "react-latex";
import 'katex/dist/katex.min.css';
import { InlineMath as IM, BlockMath as BM} from "react-katex";
// import {BlockMath as BlockMathFork} from "../../../../components/react-katext-fork/src/index"
// import MathJax from "react-mathjax";

import "./citations.css"
import "./katex_wrap.css"
// import "./numbering_titles.css"

import stilson from "./doc/moogvcf.pdf"
import huovilainen from "./doc/P_061.pdf"
import stinchcombe from "./doc/moog_ladder_tf.pdf"
import pirkle from "./doc/Addendum_A1_Moog.pdf"

import filterStructure from "./img/filter_structure.svg"
import idealMoogBode from "./img/Bode_of_ideal_moog.png"
import idealMoogBodeBackground from "./img/Bode_of_ideal_moog_background.png"
import idealMoogBodeDetail from "./img/Bode_of_ideal_moog_detail.png"
import idealFirstOrderBode from "./img/Bode_of_ideal_first_order_lp.png"
import resonanceDiagram from "./img/resonance.svg"
import resonanceDiagramFails from "./img/resonance at low f.svg"
import bodeSimpleLpf from "./img/Bode_of_simple_lpf.png"
import bodeSimpleLpfWithIdealLine from "./img/Bode_of_simple_lpf_with_ideal_line.png"
import bodeSimpleLpfWithIdealLineDetail from "./img/Bode_of_simple_lpf_with_ideal_line_detail.png"
import bodeMlfWithSimpleLpf from "./img/Bode_of_mlf_with_simple_lpf.png"
import idealAndBilinearWarped from "./img/Ideal_and_bilinear_warped.png"
import differenceBilinearWarped from "./img/Difference_bilinear_warped.png"
import idealWarpedAndCorrected from "./img/Ideal_warped_and_corrected.png"
import differenceBilinearWarpedCorrectedZoom from "./img/Difference_bilinear_warped_corrected_zoom.png"
import ZTransformPlan from "./img/Z-transform_plan.svg"
import delayMoogBode from "./img/Bode_of_mlf_with_delay.png"
import bodeBetterLpf from "./img/Bode_of_better_lpf.png"
import bodeBetterLpfDetail from "./img/Bode_of_better_lpf_detail.png"
import animationMlfDelay from"./img/animation_mlf_delay.gif"
import filterDiscreteStructure from "./img/filter_discrete_structure.svg"
import lpfEquivalent from "./img/lpf_equivalent.svg"
import filterDiscreteStructureDelayFree from "./img/filter_discrete_structure_delay_free.svg"
import bodeDelayFreeMlf from "./img/Bode_of_delay_free_mlf.png"
import animationMlfDelayFreeK3 from "./img/animation_delay_free_K_3.gif"
import animationMlfDelayFreeK3_96 from "./img/animation_delay_free_K_3_96.gif"
import rcFilter from "./img/RC_filter.svg"

// import KaTeX from 'katex';
import test_sound from "./sound/test_sine.wav"
import raw_sawtooth from "./sound/sound_unfiltered.wav"
import sound_delay_K_0 from "./sound/sound_delay_K_0.wav"
import sound_delay_K_1 from "./sound/sound_delay_K_1.wav"
import sound_delay_K_2 from "./sound/sound_delay_K_2.wav"
import sound_delay_K_3 from "./sound/sound_delay_K_3.wav"
import sound_delay_K_4 from "./sound/sound_delay_K_4.wav"  
import sound_filtered_K_0 from "./sound/sound_filtered_K_0.wav"
import sound_filtered_K_1 from "./sound/sound_filtered_K_1.wav"
import sound_filtered_K_2 from "./sound/sound_filtered_K_2.wav"
import sound_filtered_K_3 from "./sound/sound_filtered_K_3.wav"
import sound_filtered_K_3_9 from "./sound/sound_filtered_K_3_9.wav"
import sound_filtered_K_4 from "./sound/sound_filtered_K_4.wav" 
import sound_filtered_K_AD_0 from "./sound/sound_filtered_AD_K_0.wav"
import sound_filtered_K_AD_1 from "./sound/sound_filtered_AD_K_1.wav"
import sound_filtered_K_AD_2 from "./sound/sound_filtered_AD_K_2.wav"
import sound_filtered_K_AD_3 from "./sound/sound_filtered_AD_K_3.wav"
import sound_filtered_K_AD_3_9 from "./sound/sound_filtered_AD_K_3_96.wav"
import sound_filtered_K_AD_4 from "./sound/sound_filtered_AD_K_4.wav"


// import {Document} from "react-pdf"

import {ImagesViewer} from "../../../../components/ImagesViewer"
import CollapsiblePart from "../../../../components/CollapsiblePart"
import "../../../../components/collapsePart.css"
import "../../../../components/audio.css"

import ReactAudioPlayer from 'react-audio-player';
// import test_sound from "./sound/untitled.mp3"

const localCopies = [
  {key: "huovilainen_2004",
  link: huovilainen,
  },
  {key: "stinchcombe_2005",
  link: stinchcombe,
  },
  {key: "stilson_1996",
  link: stilson,
  },
  {key: "pirkle_2019",
  link: pirkle,
  },
]

const citationsBib = `
@inproceedings{huovilainen_2004,
	title = {Non-Linear Digital Implementation of the Moog Ladder Filter},
	url = {https://dafx.de/paper-archive/details.php?id=UervgvkeeDC1a4sWDluM4Q},
	abstract = {Huovilainen, A.: Non-Linear Digital Implementation of the Moog Ladder Filter, 2004},
	urldate = {2022-02-25},
	author = {Huovilainen, Antti},
	year = {2004},
}

@inproceedings{stinchcombe_2005,
	title = {Derivation of the Transfer Function of the Moog Ladder Filter},
	url = {http://www.sdiy.org/destrukto/notes/moog_ladder_tf.pdf},
	abstract = {Stinchcombe, T.: Derivation of the Transfer Function of the Moog Ladder Filter},
	urldate = {2022-02-25},
	author = {Stinchcombe, Timothy E.},
	year = {2005},
}

@misc{stilson_1996,
	title = {Analyzing the Moog VCF with Considerations for Digital Implementation},
	abstract = {Various alternatives are explored for converting the Moog four-pole Voltage Controlled Filter (VCF) to discrete-time form for digital implementation in such a way as to preserve the usefulness of its control signals. The well known bilinear transform method yields a delay-free loop and cannot be used without introducing an ad-hoc delay. Related methods from digital control theory yield realizable forms. New forms motivated by root locus studies give good results. 1 Introduction  The Voltage-Controlled Filter (VCF) designed and implemented by Robert Moog is an influential filter in the history of electronic music. In this paper, the filter is analyzed in continuous time and then several transformations of the filter into discrete time are analyzed for various properties such as efficiency, ease of implementation, and the retention of certain of the original filter's good properties, such as constant-Q, and separability of the Q and tuning controls. The Root-Locus, a particularly useful ...},
	url = {https://ccrma.stanford.edu/~stilti/papers/moogvcf.pdf},
	urldate = {2022-02-25},
	author = {Stilson, Tim and Smith, Julius},
	year = {1996},
}

@misc{pirkle_2019,
	title = {IIRFilters: The Moog Ladder Filter Biquad Style},
	url = {http://willpirkle.com/Downloads/Addendum_A1_Moog.pdf},
	urldate = {2022-02-28},
	author = {Pirkle, Will},
	year = {2019},
}

`

const simpleLpf = 
`#include <vector>
#include <cmath>

#define SAMPLERATE 44100

using namespace std;

void filter(vector<double>* out, vector<double> in, double fc){
  // out is a pointer to an empty vector
  // in is a vector containing the signal to be filtered
  // fc is the cutoff frequency in hertz

  double T = 1./SAMPLERATE;
  double wc = 2*M_PI*fc; // M_PI defined in cmath
  double w0 = wc * T;

  double a0 = w0/(w0+1);
  double b1 = (1-a0);

  double yn1 = 0;
  double sample = 0;

  for(int i=0;i<in.size();i++){
    sample = in[i] * a0 + b1 * yn1;
    out->push_back(sample);

    yn1 = sample;
  }
}
`
const betterLpf = 
`#include <vector>
#include <cmath>

#define SAMPLERATE 44100

using namespace std;

void filterImproved(vector<double>* out, vector<double> in, double fc){

  // out is a pointer to an empty vector
  // in is a vector containing the signal to be filtered
  // fc is the cutoff frequency in hertz

  double T = 1./SAMPLERATE;
  double wc = 2*M_PI*fc;  // M_PI defined in cmath
  double w0 = wc * T;

  double gamma = cos(w0) / (1+sin(w0));

  double a0 = (1-gamma)/2;
  double a1 = a0;
  double b1 = -gamma;

  double yn1 = 0;
  double xn1 = 0;
  double sample = 0;

  for(int i=0;i<in.size();i++){
    sample = in.at(i) * a0 + a1 * xn1 - b1 * yn1;
    out->push_back(sample);

    yn1 = sample;
    xn1 = in.at(i);
  }
}
`

const mlfWithDelay = 
`#include <vector>
#include <cmath>

#define SAMPLERATE 44100

using namespace std;

void MlfDelay(vector<double>* out, vector<double> in, double fc, double K){

  // out is a pointer to an empty vector
  // in is a vector containing the signal to be filtered
  // fc is the cutoff frequency in hertz
  // K is the feedback amplification (positive value)

  double T = 1./SAMPLERATE;
  double wc = 2*M_PI*fc; // cutoff rotating frequency
  double w0 = wc * T; // omega_0

  double gamma = cos(w0) / (1+sin(w0));

  double a0 = (1-gamma)/2;
  double a1 = a0;
  double b1 = -gamma;

  double y11 = 0, y21 = 0, y31 = 0, y41 = 0; // yi1 : output of filter i at step n-1
  double x11 = 0, x21 = 0, x31 = 0, x41 = 0; // xi1 : input of filter i at step n-1

  double y1, y2, y3, y4; // yi: output of the filter i at step n

  double un, xn;

  for(int i=0; i<in.size(); i++){

    // compute un, input of the first filter
    xn = in[i];
    un = xn - K * y4; // here we are using y[n-1]=y4 as an input

    // compute each first order filter's response
    y1 = un * a0 + x11 * a1 - y11 * b1;
    y2 = y1 * a0 + x21 * a1 - y21 * b1;
    y3 = y2 * a0 + x31 * a1 - y31 * b1;
    y4 = y3 * a0 + x41 * a1 - y41 * b1;

    // remember delayed parts for next iteration: outputs of the first order filters
    y11 = y1;
    y21 = y2;
    y31 = y3;
    y41 = y4;

    // remember delayed parts for next iteration: inputs of the first order filters
    x11 = un;
    x21 = y1;
    x31 = y2;
    x41 = y3;

    // store output of the filter
    out->push_back(y4);
  }
}`

const mlfDelayFree=
`#include <vector>
#include <cmath>

#define SAMPLERATE 44100

using namespace std;

void Mlf(vector<double>* out, vector<double> in, double fc, double K){

  // out is a pointer to an empty vector
  // in is a vector containing the signal to be filtered
  // fc is the cutoff frequency in hertz
  // K is the feedback amplification (positive value)

  double T = 1./SAMPLERATE;
  double wc = 2*M_PI*fc; // cutoff rotating frequency
  double w0 = wc * T; // omega_0

  double gamma = cos(w0) / (1+sin(w0));

  double a0 = (1-gamma)/2;
  double a1 = a0;
  double b1 = -gamma;

  double y11 = 0, y21 = 0, y31 = 0, y41 = 0; // yi1: output of filter i at step n-1
  double x11 = 0, x21 = 0, x31 = 0, x41 = 0; // xi1: input of filter i at step n-1

  double d1 = 0, d2 = 0, d3 = 0, d4 = 0; // di: delayed parts for filter i at step n
  double sample = 0;

  double y1, y2, y3, y4; // yi: output of filter i at step n

  double g0 = 1.; // gi is gamma_i from previous paragraph
  double g1 = a0;
  double g2 = pow(a0,2);
  double g3 = pow(a0,3);

  double alpha = 1. / (1. + K * pow(a0,4));

  double un, xn;

  for(int i=0; i<in.size(); i++){

    // compute delayed parts
    d1 = a1 * x11 - b1 * y11;
    d2 = a1 * x21 - b1 * y21;
    d3 = a1 * x31 - b1 * y31;
    d4 = a1 * x41 - b1 * y41;

    // compute un, input of the first filter
    xn = in[i];
    un = alpha * (xn - K * (g3 * d1 + g2 * d2 + g1 * d3 + g0 * d4));

    // compute each first order filter's response
    y1 = un * a0 + d1;
    y2 = y1 * a0 + d2;
    y3 = y2 * a0 + d3;
    y4 = y3 * a0 + d4;

    // remember delayed parts for next iteration: outputs of the first order filters
    y11 = y1;
    y21 = y2;
    y31 = y3;
    y41 = y4;

    // remember delayed parts for next iteration: inputs of the first order filters
    x11 = un;
    x21 = y1;
    x31 = y2;
    x41 = y3;

    // store output of the filter
    out->push_back(y4);
  }
}`


/*
Citations :
  in a sentence : Name, year^[n]  //everything links to bibliography
  reference : (Name, year)^[n]    //same
biblio :
  [n] Names, title in italic, href in monospace with hyperlink, year, (local copy)

format :
  bibtex-bibjson
 
*/

class Citation{
  bib:any;
  refCounter= 0;
  constructor(){
    // let file = fs.readFile(fileName);
    // console.log(fileName);
    this.bib = bibtexParse.toJSON(citationsBib);
    // console.log(stilson);
    for (let index = 0; index < this.bib.length; index++) {
      this.bib[index].index = 0;
    }

    // console.log(this.bibObject.getEntry("stinchcombe_2005")?.getField("title")?.toString());
  }
  full(name: String){
    let ref = this.getRef(name);
    if(!ref.index){
      return <span style={{color:"red"}}>!Ref not found!</span>
    }
    let author:String = this.parseAuthorsForInline(ref);

    return (<a id={"cite-" + ref.index} 
            className="link-nostyle" 
            href={"#bibliography-" + ref.index}>
              {author}
              <sup>
                [{ref.index}]
              </sup>
            </a>);
  }
  short(name: String){
    let ref = this.getRef(name);
    if(!ref.index){
      return <span style={{color:"red"}}>!Ref not found!</span>
    }
    let author:String = this.parseAuthorsForInline(ref);

    return (<>(<a id={"cite-" + ref.index} 
                className="link-nostyle" 
                href={"#bibliography-" + ref.index}>
                  {author}
                )
                <sup>
                  [{ref.index}]
                </sup>
              </a>
            </>);
  }
  getRef(name:String){
    let ref = {};
    // console.log("name before lowercase = ", name)
    name = name.toLowerCase()
    // console.log("name = ", name)

    for (let index = 0; index < this.bib.length; index++) {
      let element = this.bib[index]
      // console.log(element)
      let key = element.citationKey.toLowerCase();
      if(key === name){
        if(element.index === 0){
          this.refCounter += 1;
          element.index = this.refCounter;
        }
        return element
      }
      // console.log(this.bib[index]);
    }
    
    return ref
  }
  parseAuthorsForInline(ref:any){
    let author:String = ref.entryTags.AUTHOR.split(", ")[0];
    author = author.concat(", ");
    author = author.concat(ref.entryTags.YEAR);
    return author;
  }
  parseAuthorsForBibliography(au:String){
    let auList = au.split("and");
    let authors:String = "";

    for (let index = 0; index < auList.length; index++) {
      const author = auList[index];

      let auSplit = author.split(", ");
      let firstName = auSplit[1][0] + ".";

      authors = authors.concat(firstName + " ");
      authors = authors.concat(auSplit[0]);
      if(index !== (auList.length - 1)){
        authors = authors.concat(" and ");
      }
      
    }

    // authors.

    return authors;
  }
  getLocalCopy(entryName:String){
    entryName = entryName.toLowerCase()
    for (let index = 0; index < localCopies.length; index++) {
      let key = localCopies[index].key.toLowerCase();
      if(key === entryName.toLowerCase()){
        return localCopies[index].link;
      }
    }
    return ""
  }
  bibliography(){
    // for (let index = 0; index < this.bib.length; index++) {
    //   this.bib[index].index = index+1;
    //   console.log(this.bib[index]);
    // }
    const data:JSX.Element[] = [];

    this.bib.sort(function(a:any, b:any){return a.index - b.index});

    for (let index = 0; index < this.bib.length; index++) {
      let ref = this.bib[index];
      if(ref.index === 0) continue;
      let authors = this.parseAuthorsForBibliography(ref.entryTags.AUTHOR);
      let localCopy = this.getLocalCopy(ref.citationKey);
      let localCopyLink = <></>
      if(localCopy !== ""){
        localCopyLink = <>{", "}<br/> <a href={localCopy} className="link-ul" 
        style={{
          // fontFamily: "monospace", 
          // fontSize:"smaller"
        }}>local copy</a></>
      }
      data.push(
        <div className="bib-entry">
          <span className="bib-bullet">
            <a className="link-nostyle" href={"#cite-" + ref.index}>
              [{ref.index}]
            </a>
          </span>
          <span className="bib-item" id={"bibliography-" + ref.index}>
            ↑{" "}
            {authors}{", "}
            <span style={{fontStyle: "italic"}}>{ref.entryTags.TITLE}</span>{", "}
            <br/>
            <a href={ref.entryTags.URL} className="link-ul" 
            style={{fontFamily: "monospace", fontSize:"smaller"}}>
              {ref.entryTags.URL}
            </a>
            {", "}{ref.entryTags.YEAR}
            {localCopyLink}
          
          </span>
        </div>
      )
      // data.push(<><br/> <br/> </>)
    }
    return (<div className="bibli">
    {data}
    </div>)
  }
}


class BlockMath extends Component <any, {}>{
  render(){
    return (<div style={{overflowX:"auto", maxWidth:"80vw"}}><BM>{this.props.children}</BM></div>);
  }
}

class InlineMath extends Component <any, {}>{
  render(){
    if(this.props.children){
      return (
        <>
          
          <IM>{this.props.children}</IM>
          
        </>
        );  
    }
    return (
    <>
      <span className="inline-math" style={{
        // overflowX:"auto", 
        // overflowY: "hidden", 
        // maxWidth:"80vw", 
        // display:"inline-block", 
        // verticalAlign:"-33%", 
        // scrollbarWidth:"none",  /* Firefox */
        // msOverflowStyle: "none",/* IE and Edge */
        // &::WebkitScrollbar: {display:"none"},
        }}>
        <IM math={this.props.math}/>
      </span>
    </>
    );
  }
}

class SectionTitle extends Component <any, {}>{
  static n = [0];

  resetCounters(level:number){
    if(level<SectionTitle.n.length){
      SectionTitle.n[level] += 1;
    }else{
      SectionTitle.n.push(1);
    }
    for(let i=level+1;i<SectionTitle.n.length;i++){
      SectionTitle.n[i] = 0;
    }
  }  
  get_Prefix(level:number){
    let p = "";
    for(let i=0;i<=level;i++){
      p+=SectionTitle.n[i] + "."
    }
    p += " "
    return p;
  }

  render(){
    const {level, ...filteredProps} = this.props;
    if(this.props.level>4){
      return(<></>)
    }
    this.resetCounters(this.props.level);
    return (
      <>
      {createElement("h"+(2+this.props.level), filteredProps, this.get_Prefix(this.props.level) + this.props.children)}
      </>
    )
    
  }
}

class H2 extends Component <any, {}>{
  render(){
    return (
      <SectionTitle level={0} {...this.props}>{this.props.children}</SectionTitle>
    );
  }
}
class H3 extends Component <any, {}>{
  render(){
    return (
      <SectionTitle level={1} {...this.props}>{this.props.children}</SectionTitle>
    );
  }
}
class H4 extends Component <any, {}>{
  render(){
    return (
      <SectionTitle level={2} {...this.props}>{this.props.children}</SectionTitle>
    );
  }
}
class H5 extends Component <any, {}>{
  render(){
    return (
      <SectionTitle level={3} {...this.props}>{this.props.children}</SectionTitle>
    );
  }
}


class Content extends Component <any, {}>{
  render() {
    let cite = new Citation();
    return (
      <>

        <div style={{ backgroundImage:`url(${idealMoogBodeBackground})`,backgroundRepeat:"no-repeat",
        backgroundSize: "cover",
        minHeight: "10em",
        // paddingLeft: "1em",
        backgroundBlendMode: "darken",
        backgroundColor: "#282c3499",
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        }}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            margin: "1em",
            
          }}>
            <h2 className="nocount" id="title" style={{marginTop: 0}}>Designing a Moog style filter</h2>
            How to implement a digital filter which mimics the sound of the classical Moog ladder filter
            <br/>Implementation in c++

          </div>
        </div>
        <br />
        [NOTE] Writing in progress
        <CollapsibleTableOfContents highestHeading="2"/>
        <ScrollToTop/>

        <h2 id="introduction">Introduction</h2>
        For my software synths, I have used various filters with various sounds, 
        but I have never been able to recreate one that is as pleasing to use as a Moog style ladder filter.
        In this article, I will try and give the theoretical background necessary to implement such a filter, 
        as well as present an implementation of this type of filter in c++.
        <br />Many papers have been devoted to the original analog filter and its digital implementations, 
        so here I will only present my modest attempt, based on some of these papers. 
        A complete <a className="link-ul" href="#bibliography">bibliography</a> is provided at the end of the article.
        <br /> This implementation will be included in one of my other projects (Scharm).
        
        <br />
        <br />
        The Moog ladder filter has a 'warm' sound that is hard to describe,
        
        a -80 dB/octave attenuation above its cutoff frequency, 
        and is a resonant filter that can self-oscillate.
        <H2 id="mlf-arch">Architecture of a Moog ladder filter</H2>
        
        I will not try here to derive the transfer function of the filter from its physical implementation, 
        an attempt can be found in {" "}
        {cite.full("stinchcombe_2005")}.

        The structure of the filter is described by this block diagram {cite.short("stilson_1996")}:
        <ImagesViewer imagePath={filterStructure} alt="Block diagram of the Moog ladder filter" 
          style={{width:'80%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Structure of the Moog ladder filter from {cite.full("stilson_1996")}, redrawn with TikZ</div>
        
        <br/>
        Notation:
        <ul>
          <li><InlineMath math={"H_1(\\omega) = \\frac{1}{1 + i\\frac{\\omega}{\\omega_c}}"}/> represents the Laplace transform of first order low-pass filters</li>
          <li><InlineMath math="\omega_c"/> is the cutoff angular frequency of each filter</li>
          <li><InlineMath math="x(t)"/> is the input of the whole filter and <InlineMath math="X(\omega)"/> its Laplace transform</li>
          <li><InlineMath math="y(t)"/> is the output of the whole filter and <InlineMath math="Y(\omega)"/> its Laplace transform</li>
          <li>the block with <InlineMath math="k"/> is an amplifier and <InlineMath math="k"/> has a positive value</li>
        </ul>
       
        
        We will also use the notation <InlineMath math="f_c"/> for the cutoff frequency (reminder:{" "} 
        <InlineMath math="\omega_c= 2\pi f_c"/>
        ).
        From the diagram,

        <BlockMath>{"Y(\\omega) = H_1(\\omega)^4 \\cdot (X(\\omega) - kY(\\omega))  "}</BlockMath>
        
        <BlockMath>{"Y(\\omega)\\cdot(1 + k H_1(\\omega)^4) = H_1(\\omega)^4 X(\\omega)"}</BlockMath>
        <BlockMath>{"\\frac{Y(\\omega)}{X(\\omega)} = \\frac{H_1(\\omega)^4 }{1 + k H_1(\\omega)^4} "}</BlockMath>

        
        The transfer function of the filter is :

        <BlockMath>{`\\begin{aligned}H(\\omega) &= \\frac{Y(\\omega)}{X(\\omega)} \\\\
        H(\\omega) &= \\frac{H_1(\\omega)^4 }{1 + k H_1(\\omega)^4} \\\\
        H(\\omega) &= \\frac{1}{k + \\frac{1}{H_1(\\omega)^4} } \\\\
        H(\\omega) &= \\frac{1}{k + (1 + i\\frac{\\omega}{\\omega_c})^4 } \\end{aligned}`}</BlockMath>
        
        The parameter <InlineMath math="k"/> varies from 0 to 4, 
        and corresponds to the resonance of the filter :

        <ImagesViewer imagePath={idealMoogBode} alt="Bode plot for the Moog ladder filter" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Bode plot for the filter, computed from the transfer function for different values of <InlineMath math="k"/>. The cutoff frequency is 100 Hz</div>
        
        <br />
        As <InlineMath math="k"/> gets near 4, 
        the resonance of the filter increases near the cutoff frequency. For <InlineMath math="k=4"/> {" "}
        and <InlineMath math="\omega = \omega_c"/> the amplitude tends to infinity as the denominator tends to 0.
        
        <br />The filter attenuation approaches -80 dB/decade above the cutoff frequency 
        and the phase shift is <InlineMath math="-\pi"/>  at the cutoff, 
        which is expected for a fourth order low-pass filter. 
        What is unexpected is the attenuation below the cutoff which is more pronounced as <InlineMath math="k"/> increases,
        and the fact that the resonant frequency seems to vary with <InlineMath math="k"/> :
        
        <ImagesViewer imagePath={idealMoogBodeDetail} 
          alt="Amplitude response of the Moog ladder filter, detail" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Detail of the filter's amplitude response for different values of <InlineMath math="k"/>. The cutoff frequency is still 100 Hz</div>
        
        <br />Here we can see that :
        <ol>
          <li>the resonant frequency approaches <InlineMath math="f_c"/> as <InlineMath math="k"/> approaches 4,</li>
          <li>and there is quite a strong attenuation of low frequencies, 
        the attenuation increasing as <InlineMath math="k"/> increases.</li>
        </ol>
        These oddities can be viewed as features of the filter, or defects. {" "}
        {cite.full("huovilainen_2004")} sees the cutoff frequency shift as a characteristic that has to be corrected (see §5.3),
        while {cite.full("pirkle_2019")} gives a way to compensate for the bass amplitude drop.
        <H3 id="mlf-principle">Moog ladder filter mechanism</H3>
        The originality of this filter is that it uses the first order low-pass filters' phase shift to achieve the resonance.
        
        <br />An ideal first order low-pass filter has the following transfer function :
        <BlockMath>{"H_1(\\omega) = \\frac{1}{1 + i\\frac{\\omega}{\\omega_c}}"}</BlockMath>
        And its frequency response looks like this : 
        <ImagesViewer imagePath={idealFirstOrderBode} 
          alt="Bode plot of a first order low pass filter" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Bode plot for an ideal first order low pass filter, computed from its transfer function. The cutoff frequency is 100 Hz</div>
        
        <br />
        Two important notes :
        <ol>
          <li>
            The attenuation at <InlineMath math="f_c"/> is -3dB (more precisely, the amplitude is multiplied by <InlineMath math="\frac{1}{\sqrt{2}}"/>, 
            and <InlineMath math="20\log_{10}(\frac{1}{\sqrt{2}})\approx -3.01"/>)
          </li>
          <li>
            the phase shift at <InlineMath math="f_c"/> is <InlineMath math="-\frac{\pi}{4}"/>
          </li>
        </ol>
        So what happens when a signal at <InlineMath math="f_c"/> enters a Moog ladder filter ?
        <ImagesViewer imagePath={resonanceDiagram} 
          alt="Diagram of a sine wave at the cutoff frequency being processed by the filter" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          What happens to the amplitude and phase of a signal at <InlineMath math="f_c"/> in the Moog filter, when <InlineMath math="k=4"/>. {" "}
          <br /><InlineMath math="G"/> represents the amplitude, and <InlineMath math="d\varphi"/> the phase shift.
          <br />The effect of the sum <InlineMath math="\Sigma"/> is not represented.</div>
        <br />
        Each first-order low pass filter shifts the phase of the signal by <InlineMath math="\frac{\pi}{4}"/> at the cutoff frequency.
        So after the fourth filter, the signal at <InlineMath math="f_c"/> is shifted by <InlineMath math="-\pi"/>, 
        ie. it is inverted (in phase opposition with the input). The signal is then inverted by <InlineMath math="-k"/> (now it is in phase with the input) and added to the input.
        So the signal at <InlineMath math="f_c"/> is added to itself, in phase with itself at the input of the filter, ie. it is amplified via constructive interference.
        So an input signal at <InlineMath math="f_c"/> is amplified by the filter, ie. a resonance occurs at <InlineMath math="f_c"/>.
        <br />
        The factor <InlineMath math="k = 4"/> comes from the fact that after each first order filter, 
        the signal amplitude at <InlineMath math="f_c"/> is divided by <InlineMath math="\sqrt{2}"/>,
        so after four filters, it is attenuated by <InlineMath math="\sqrt{2}^4=4"/>. 
        Multiplying by 4 restores its initial amplitude.
        
        <br />
        <br />
        The explanation above is a vast simplification. 
        This reasoning doesn't work if we try and understand what happens for low frequencies : 
        after the four first order filters, {" "}
        <InlineMath math="G"/> should be 1 (almost no attenuation) and <InlineMath math="d\varphi"/> {" "}
        should be 0 (almost no phase shift); following the multiplication by <InlineMath math="- k"/>, 
        if we assume <InlineMath math="k=4"/> we get a signal with <InlineMath math="G = 4"/>{" "}
        and <InlineMath math="d\varphi = -\pi"/>. 
        After the sum, we have an inverted signal with an amplitude of 3.

        <ImagesViewer imagePath={resonanceDiagramFails} 
          alt="Diagram of a sine wave at a low frequency being processed by the filter, it shows
          that the explanation on the previous diagrams doesn't work at other frequencies" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Illustration of how the explanation fails at low frequencies. 
          <br /> If this simplification was accurate, the amplitude at low frequencies should diverge.
        </div>
        <br />
        <br />So I don't have yet a simple explanation of why there is a resonant frequency shift or an
        attenuation of low frequencies. 
        <br />
        <br />
        Note that in this section, we are using a linear approximation of the filter, 
        and it may be interesting to try and implement a non-linear 
        (ie. closer to the real filter) approximation (see {cite.full("huovilainen_2004")}).

        <H2 id="lpf-first-attempt">First order low-pass filter</H2>
        <H3 id="lpf-theory">A bit of theory</H3>
        As we saw before, the transfer function of an ideal first order low pass filter is the following :
        <BlockMath>{"H_1(\\omega) = \\frac{1}{1 + i\\frac{\\omega}{\\omega_c}}"}</BlockMath>
        where <InlineMath math="\omega_c"/> is the cutoff angular frequency of the filter.
        Let's rearrange that a bit :
        <BlockMath>{"H_1(\\omega) = \\frac{Y(\\omega)}{X(\\omega)} = \\frac{\\omega_c}{\\omega_c + i \\omega }"}</BlockMath>
        <BlockMath>{"(\\omega_c + i \\omega ) Y(\\omega) = \\omega_c X(\\omega) "}</BlockMath>



        From that we can extract the differential equation of the filter in the time domain 
        (remembering that multiplying by <InlineMath math="i \omega" /> in the frequency domain corresponds to a time derivation in the time domain) : 

        <BlockMath>{"\\omega_c y(t) +  \\frac{\\mathrm{d } y(t)}{\\mathrm{d}t}  = \\omega_c x(t) "}</BlockMath>
        <BlockMath>{"y(t) = x(t) - \\frac{1}{\\omega_c }\\frac{\\mathrm{d } y(t)}{\\mathrm{d}t}"}</BlockMath>

        Admittedly, we typically derive the transfer function from the difference equation and not the inverse, 
        but I find it easier to remember the transfer function.
        <br />
        <br /> We are trying to implement a discrete version of this filter. The signals are sampled with a sampling period <InlineMath math="T"/> and
        {" "}<InlineMath math="x_n"/> is the input signal at <InlineMath math="nT"/> where <InlineMath math="n"/> is a positive integer.
        Likewise, <InlineMath math="y_n"/> is the output signal at <InlineMath math="nT"/>.
        <br />A simple way to get a discrete equation from the one above is to make the following approximation :
        <BlockMath>{"\\frac{\\mathrm{d } y(t)}{\\mathrm{d}t} \\sim \\frac{1}{T}(y_n - y_{n-1})"}</BlockMath>

        The differential equation becomes :
        <BlockMath>{`\\begin{aligned}
          y_n &= x_n - \\frac{1}{T\\omega_c }(y_n - y_{n-1})\\\\
          y_n(1+ \\frac{1}{T\\omega_c }) &= x_n + y_{n-1}\\frac{1}{T\\omega_c }\\\\
          y_n &= \\frac{T\\omega_c}{T\\omega_c +1}x_n +  \\frac{1}{T\\omega_c +1}y_{n-1}\\\\
          y_n &= \\alpha \\cdot x_n +  (1-\\alpha) \\cdot y_{n-1}
        \\end{aligned}`}</BlockMath>

        where <InlineMath math="\alpha=\frac{1}{1+\frac{1}{T\omega_c}}"/>. 
        This is not the best approximation possible, but let's try it anyway.

        <H3 id="simple-lpf-implementation">Implementation of a simple low-pass filter</H3>
        <CodeBlock>{simpleLpf}</CodeBlock>

        Of course, I would not implement the full filter like this, this is just to test the actual filtering algorithm. 
        I am using vectors for convenience, but they will not make it to the final implementation of the filter.
        <H3 id="simple-lpf-results">Results</H3>

        In order to get the frequency response, I filter a unit sample function (which is the discrete equivalent of a Dirac), 
        then I extract the spectrum and plot the amplitude and phase of the spectrum.
        <br /> The frequency response of this implementation is represented here:  
        <ImagesViewer imagePath={bodeSimpleLpf} 
          alt="Frequency response of a simple implementation of a first order low-pass filter" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
        Bode plot of the low pass filter we implemented in previous section
        <br />The cutoff frequency is still 100Hz</div>
        <br /> It certainly looks like a first order low-pass filter, the attenuation is around -3dB at <InlineMath math="f_c"/>,
        and approaches -20 dB/decade above the cutoff frequency. The phase shift is around <InlineMath math="\frac{\pi}{4}"/> at <InlineMath math="f_c"/>,
        but is all over the place at high frequencies. But these frequencies are so attenuated that it probably doesn't matter too much.
        <br /> To show if this simple code is a good approximation of an ideal first order low-pass filter, 
        the ideal frequency response is added on the next graph:

        <ImagesViewer imagePath={bodeSimpleLpfWithIdealLine} 
          alt="Frequency response of a simple implementation of a first order low-pass filter, 
          with the representation of the frequency response of an ideal first order low pass filter" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
        Bode plot of the low pass filter we implemented in previous section, with the frequency response of an ideal low pass filter added in white
        <br />The cutoff frequency is still 100Hz</div>

        <br />For our Moog filter to work, it is really important that 
        the attenuation is precisely <InlineMath math="\frac{1}{\sqrt{2}}"/> and 
        the phase shift is <InlineMath math="\frac{\pi}{4}"/> at <InlineMath math="f_c"/>.
        Zooming in, we start to see the difference between our implementation and the theoretical values:

        <ImagesViewer imagePath={bodeSimpleLpfWithIdealLineDetail} 
          alt="Frequency response of a simple implementation of a first order low-pass filter, 
          with the representation of the frequency response of an ideal first order low pass filter, detail with differences annotated" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
        Bode plot of the low pass filter we implemented in previous section, with the frequency response of an ideal low pass filter added in white
        <br />Zoomed around the cutoff frequency to emphasize the differences
        <br />The cutoff frequency is still 100Hz
        </div>
        <br />
        So we have a 1% difference in gain at <InlineMath math="f_c"/>, which is pretty bad, 
        and half a percent difference in phase shift, which is only a bit better. Overall, can this approximation work with the moog filter ?

        <br /> We will see later how to implement a moog filter, but just to see how this approximation fares, 
        this is how the filter would like if we use it :

        <ImagesViewer imagePath={bodeMlfWithSimpleLpf} 
          alt="Frequency response of a simple implementation of a first order low-pass filter, 
          with the representation of the frequency response of an ideal first order low pass filter" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
        Bode plot of the low pass filter we implemented in previous section, with the frequency response of an ideal low pass filter added in white
        <br />The cutoff frequency is still 100Hz</div>


        <H2 id="better-lpf">Improving the low-pass filter</H2>
        <H3 id="better-lpf-theory">Again, some theory</H3>
        <H4 id="z-transform">A not so quick reminder on Z-transform</H4>
        <H5 id="z-transform-bilinear-transform">Z-transform and bilinear transform</H5>

        To improve our low-pass filter, we'll be using the Z-transform. 
        The Z-transform <q>can be considered as a discrete-time equivalent of 
        the Laplace transform</q> (<a className="link-ul" href="https://en.wikipedia.org/wiki/Z-transform">
        wikipedia</a>). 
        It allows us to convert the continuous-time complex transfer function into a 
        discrete-time complex one, and then convert it to a discrete time equation for our signal.

        <br />
        <br />
        To convert a continuous frequency-domain equation in a discrete one, we apply a {" "}
        <a className="link-ul" href="https://en.wikipedia.org/wiki/Bilinear_transform">
        bilinear transform</a>. The Z-domain and Laplace domain are linked by this equation :
        <BlockMath>{"z = e^{sT}"}</BlockMath>
        where <InlineMath math="s"/> is the Laplace complex variable, <InlineMath math="T"/> {" "}
        is the sampling period and <InlineMath math="z"/> is the Z-domain variable, analog to the <InlineMath math="s"/> {" "}
        variable of the Laplace transform. Or inversely:
        <BlockMath>{"s = \\frac{1}{T}\\ln(z)"}</BlockMath>
        
        As we are only interested in the frequency domain (ie. the <InlineMath math="i\omega"/> axis 
        of the complex Laplace plane), what this does is map the <InlineMath math="j\omega"/> axis onto the {" "}
        <InlineMath math="z"/> unit circle.
        <br />
        The bilinear takes its name from the bilinear expansion of the natural 
        logarithm (based on the Taylor series of the inverse hyperbolic tangent function):
        <BlockMath>{`
        \\begin{aligned}
        \\ln(z) &= 2 \\cdot \\mathrm{artanh}\\left(\\frac{z-1}{z+1}\\right) \\\\
             &= 2 \\left(\\frac{z-1}{z+1} + \\frac{1}{3}\\left(\\frac{z-1}{z+1}\\right)^{3}
             + \\frac{1}{5}\\left(\\frac{z-1}{z+1}\\right)^{5} + \\dots\\right)\\\\
             &\\approx 2 \\cdot \\frac{z-1}{z+1}
        \\end{aligned}
        `}</BlockMath>

        So we use the following approximation:
        <BlockMath>{`
        \\begin{aligned}
        s &= \\frac{2}{T} \\cdot \\frac{z-1}{z+1} \\\\
          &= \\frac{2}{T} \\cdot \\frac{1-z^{-1}}{1+z^{-1}}
        \\end{aligned}
        `}</BlockMath>

        We are only interested in the frequency domain (ie. the <InlineMath math="i\omega"/> axis 
        of the complex Laplace plane), so we will use:
        <BlockMath>{"i\\omega = \\frac{2}{T} \\cdot \\frac{1-z^{-1}}{1+z^{-1}}"}</BlockMath>
        <br />
        <br />
        How good is this approximation ? We can compare the value of <InlineMath math="\arg(z)"/> {" "}
        given by the exact formula and the bilinear approximation :
        <BlockMath>{`
        \\begin{aligned}
          \\arg_{exact}(z) &= \\arg(e^{i\\omega T}) = \\omega T \\\\
          \\arg_{approx}(z)&= \\arg\\left(\\frac{2 + i\\omega T}{2 - i\\omega T}\\right) \\\\
                   &= \\arg\\left(\\frac{(2 + i\\omega T)^2}{(2 - i\\omega T)(2 + i\\omega T)}\\right) \\\\
                   &= \\arg\\left((2 + i\\omega T)^2\\right) \\\\
                   &= \\arg\\left(4 + 4i\\omega T - \\omega^2T^2\\right) \\\\
                   &= \\arctan\\left(\\frac{4\\omega T}{4 - \\omega^2T^2}\\right) \\\\
                   &= \\arctan\\left(\\frac{\\omega T}{1 - \\left(\\frac{\\omega  T}{2}\\right)^2}\\right) \\\\
                   &\\approx \\frac{\\omega T}{1 - \\left(\\frac{\\omega  T}{2}\\right)^2} - \\frac{1}{3}\\left(\\frac{\\omega T}{1 - \\left(\\frac{\\omega T}{2}\\right)^2}\\right)^3
        \\end{aligned}
        `}</BlockMath>
        The last approximation comes from the Taylor series of <InlineMath math="\arctan"/>. 
        The pole at <InlineMath math="\omega T = 2"/> is ignored here, this is just to give the general shape of the approximation.
        So the error is small as <InlineMath math="\omega"/> is close to 0, but increases with <InlineMath math="\omega"/>. 

        <ImagesViewer imagePath={idealAndBilinearWarped} 
          alt="comparison between the exact formula and the bilinear approximation for arg(z)" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
        Comparison between the exact formula and the bilinear approximation for <InlineMath 
        math="\arg(z)"/>
        <br />I chose <InlineMath math="T = \frac{1}{44100}"/> as an example
        </div>


        <ImagesViewer imagePath={differenceBilinearWarped} 
          alt="absolute error in the bilinear approximation for arg(z)" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
        Absolute error in the bilinear approximation for <InlineMath 
        math="\arg(z)"/>
        <br />The sample rate is still 44100.
        </div>

        <br />
        Let's rename <InlineMath math="\omega_Z"/> the angular frequency in the Z-domain and {" "}
        <InlineMath math="\omega_s"/> the corresponding frequency in the Laplace plane.
        When we want to evaluate a transfer function at <InlineMath math="\omega_s"/>, 
        it will be evaluated in the Z-domain at <InlineMath math="\omega_Z"/>. 
        The fact that <InlineMath math="\omega_s \neq \omega_Z"/> is called frequency warping, 
        and is more pronounced at higher frequencies.
        <br />For our filters, we want the two frequencies to be the same at the cutoff, ie.:

        <BlockMath>{`\\omega_{c,s} = \\omega_{c,Z}`}</BlockMath>

        To achieve this, we will go back to the bilinear approximation, and generalize:

        <BlockMath>{`
        s = K \\cdot \\frac{1-z^{-1}}{1+z^{-1}}
        `}</BlockMath>
        
        where <InlineMath math="K"/> is a new constant 
        (previously fixed at <InlineMath math="\frac{2}{T}"/>).
        <br />Doing the same operations as before, we get:
        <BlockMath>{`
        \\omega_Z T= \\arctan\\left(\\frac{2\\cdot \\omega_s / K}{1-(\\omega_s / K)^2}\\right)
        `}</BlockMath>
        We want the frequencies to be equal at <InlineMath math="\omega_c"/>, so:
        <BlockMath>{`
        \\begin{aligned}
          \\omega_c T&= \\arctan\\left(\\frac{2\\cdot \\omega_c / K}{1-(\\omega_c / K)^2}\\right) \\\\
          \\tan(\\omega_c T) &= \\frac{2\\cdot \\omega_c / K}{1-(\\omega_c / K)^2}
        \\end{aligned}
        `}</BlockMath>
        Using the tangent half-angle substitution:
        <BlockMath>{`
        \\begin{aligned}
          \\tan(\\omega_c T) &= \\frac{2\\cdot \\omega_c / K}{1-(\\omega_c / K)^2}\\\\
          \\frac{2\\tan(\\omega_c T/2)}{1 - \\tan(\\omega_c T/2)^2} &= \\frac{2\\cdot \\omega_c / K}{1-(\\omega_c / K)^2}
        \\end{aligned}
        `}</BlockMath>
        By identification:
        <BlockMath>{`
        \\begin{aligned}
          \\tan(\\omega_c T/2) &= \\omega_c / K \\\\
          K &= \\frac{\\omega_c}{\\tan\\left(\\frac{\\omega_c T}{2}\\right)}
        \\end{aligned}
        `}</BlockMath>

        When <InlineMath math="\omega_c\sim 0"/>, <InlineMath math="\tan\left(\frac{\omega_c T}{2}\right) \sim \frac{\omega_c T}{2}"/> and {" "}
        <InlineMath math="K \sim \frac{\omega_c}{\frac{\omega_c T}{2}}\sim\frac{2}{T}"/>, 
        the original value of <InlineMath math="K"/>.

        <br />
        To sum up, if we use the following substitution, we will perform a bilinear transform that lets us 
        use our transfer function in discrete time while preserving the response at the cutoff frequency :
        <BlockMath>{"i\\omega \\leftarrow \\frac{\\omega_c}{\\tan\\left(\\frac{\\omega_c T}{2}\\right)} \\frac{1-z^{-1}}{1+z^{-1}}"}</BlockMath>

        Note that there are more rigorous ways to compute <InlineMath math="K"/>, but the value is the same.

        <br />Let's see what the correction looks like on an example:
        <ImagesViewer imagePath={idealWarpedAndCorrected} 
          alt="comparison between the exact formula, the warped and corrected bilinear approximations for arg(z)" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
        Comparison between the exact formula and the two bilinear approximation for <InlineMath 
        math="\arg(z)"/>
        <br />The sample rate is 44100 and the cutoff frequency is 100Hz.
        <br />The two approximations overlap.
        </div>
        <br />
        The absolute error overall looks the same as the uncorrected approximation, 
        so we will directly show how the correction performs at the cutoff frequency:
        
        <ImagesViewer imagePath={differenceBilinearWarpedCorrectedZoom} 
          alt="absolute error in the bilinear approximations for arg(z), zoomed around cutoff freq" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
        Absolute error in the bilinear approximations for <InlineMath 
        math="\arg(z)"/>, zoomed around the cutoff frequency (100 Hz)
        <br />The sample rate is still 44100.
        </div>
        <br />We see that at the cutoff frequency, the error of the corrected approximation 
        is much closer to 0 than the error of the first one. The error is in
        the numeric precision of the language I was using. The curve changes direction
        because we are plotting the absolute error.

        <br />
        <br />Now that we can transform our transfer function to the Z-domain, how do we go back 
        to time domain ?
        <H5 id="canonical-form">Canonical form and going back to time</H5>

        We will use two properties of the Z-transform:
        <ul>
          <li>Time delay: if <InlineMath math="a[n]"/> is a discrete signal and <InlineMath math="A(z)"/> its Z-transform,
          then the Z-transform of <InlineMath math="a[n-1]"/> is <InlineMath math="z^{-1}A(z)"/>
          </li>
          <li>Linearity: if <InlineMath math="a[n]"/> and <InlineMath math="b[n]"/> are two discrete signals and {" "}
          <InlineMath math="A(z)"/> and <InlineMath math="B(z)"/> their Z-transforms,
          then the Z-transform of <InlineMath math="a[n]+b[n]"/> {" "}
          is <InlineMath math="A(z)+B(z)"/>.
          </li>
        
        </ul>
        <br />
        <br />
        Let <InlineMath math="x[n]"/> and <InlineMath math="y[n]"/> be discrete signals,{" "}
        <InlineMath math="X(z)"/> and <InlineMath math="Y(z)"/> their respective Z-transforms,{" "}
        <InlineMath math="a_i"/> and <InlineMath math="b_i"/> real numbers.
        If:
        <BlockMath>{`y[n] = a_0\\cdot x[n] + a_1\\cdot x[n-1] + b_1\\cdot y[n-1]`}</BlockMath>
        Then:
        <BlockMath>{`
        \\begin{aligned}
        Y(z) &= a_0\\cdot X(z) + a_1\\cdot X(z)\\cdot z^{-1} + b_1\\cdot Y(z)\\cdot z^{-1} \\\\
             &= X(z)[a_0 + a_1\\cdot z^{-1}] + Y(z)[b_1\\cdot z^{-1}]\\\\
        Y(z)[1 - b_1\\cdot z^{-1}] &= X(z)[a_0 + a_1\\cdot z^{-1}] \\\\
        \\frac{Y(z)}{X(z)} &= \\frac{a_0 + a_1\\cdot z^{-1}}{1 - b_1\\cdot z^{-1}}
        \\end{aligned}
        `}</BlockMath>
        If <InlineMath math="y"/> is the output signal of a system and <InlineMath math="x"/> the input signal, 
        then <InlineMath math="\frac{Y(z)}{X(z)}"/> is the Z-transform of the system's transfer function.
        <br />So, if we can write a Z-domain transfer function under the form:
        <BlockMath>{`
        H(z) = \\frac{\\displaystyle\\sum_{i=0}^{k}a_i\\cdot z^{-i}}
                     {1 - \\displaystyle\\sum_{j=1}^{m}b_j\\cdot z^{-j}}
        `}</BlockMath>
        we can write the inverse Z-transform and get the following equation for the signals:
        <BlockMath>{`
        y[n] = \\displaystyle\\sum_{i=0}^{k}a_i\\cdot x[n-i] + 
               \\displaystyle\\sum_{j=1}^{m}b_j\\cdot y[n-j]
        `}</BlockMath>
        <br />Now, we can get a discrete frequency-domain transfer equation for our filters 
        from their Laplace transfer equation, and get a discrete time-domain equation from that, 
        that lets us compute the output of a filter in few linear operations.
        <br />

        <ImagesViewer imagePath={ZTransformPlan} 
          alt="summary of all the steps to go from the filter model to the discrete, time-domain equation" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
        Summary of the method we use to go from a real filter to a usable discrete equation </div>
        
        <br />After this lengthy reminder, let's use these tools for our specific filter.

        <H4 id="better-lpf-discrete equation">A new discrete equation</H4>

        We'll start again from the transfer function of a first order low-pass filter:
        <BlockMath>{"H(\\omega) = \\frac{1}{1+i\\frac{\\omega}{\\omega_c}}"}</BlockMath>
        From there we will apply the bilinear transform by making the following substitution:
        <BlockMath>{"i\\omega \\leftarrow K \\frac{1-z^{-1}}{1+z^{-1}}"}</BlockMath>

        So the transfer function becomes :
        <BlockMath>{`
        \\begin{aligned}
        H(z) &= \\frac{1}{1+ K \\frac{1-z^{-1}}{1+z^{-1}} \\frac{1}{\\omega_c} } \\\\
             &= \\frac{1+z^{-1}}{1+z^{-1} + \\frac{K}{\\omega_c}(1-z^{-1})}\\\\
             &= \\frac{1+z^{-1}}{ (1+\\frac{K}{\\omega_c}) + z^{-1}( 1 - \\frac{K}{\\omega_c} )} \\\\
             &= \\frac{1+z^{-1}}{ \\frac{\\omega_c + K}{\\omega_c} + z^{-1}\\frac{\\omega_c-K}{\\omega_c}} \\\\
        H(z) &= \\frac{\\frac{\\omega_c}{\\omega_c+K} + z^{-1}\\frac{\\omega_c}{\\omega_c+K}}{ 1 + z^{-1}\\frac{\\omega_c-K}{\\omega_c+K}}
        \\end{aligned}
        `}</BlockMath>

        Then we can go back in the time domain:
        <BlockMath>{`
        \\begin{aligned}
          y_n &= \\frac{\\omega_c}{\\omega_c+K} x_n + \\frac{\\omega_c}{\\omega_c+K} x_{n-1} - \\frac{\\omega_c-K}{\\omega_c+K} y_{n-1}\\\\
              &= a_0x_n + a_1x_{n-1} - b_1y_{n-1}
        \\end{aligned}
        `}</BlockMath>

        If we let <InlineMath>{"\\gamma = -\\frac{\\omega_c-K}{\\omega_c+K}"}</InlineMath>, then:
        <ul>
          <li><InlineMath>{"a_0 = a_1 = \\frac{1-\\gamma}{2}"}</InlineMath></li>
          <li><InlineMath>{"b_1= -\\gamma"}</InlineMath></li>
        </ul>

        To compensate for frequency warping when doing the Z transform, we choose <InlineMath math="K"/> such as:
        <BlockMath>{"\\omega_c = K \\cdot \\tan\\left(\\frac{\\omega_c T}{2}\\right)"}</BlockMath>
        Let <InlineMath math="\omega_0 = \omega_c T"/>:
        <BlockMath>{"K = \\frac{\\omega_c}{\\tan\\left(\\frac{\\omega_0}{2}\\right)}"}</BlockMath>
        Then:
        <BlockMath>{`
        \\begin{aligned}
        
          \\gamma &= -\\frac{\\omega_c-K}{\\omega_c+K} \\\\
                  &= -\\frac{\\omega_c - \\frac{\\omega_c}{tan(\\frac{\\omega_0}{2})}}{\\omega_c + \\frac{\\omega_c}{tan(\\frac{\\omega_0}{2})}} \\\\
                  &= -\\frac{1 - \\frac{1}{tan(\\frac{\\omega_0}{2})}}{1 + \\frac{1}{tan(\\frac{\\omega_0}{2})}} \\\\
                  &= -\\frac{tan(\\frac{\\omega_0}{2}) -1 }{tan(\\frac{\\omega_0}{2}) +1}\\\\
                  &= \\frac{1-tan(\\frac{\\omega_0}{2})}{1+tan(\\frac{\\omega_0}{2})} \\\\
                  &= \\frac{cos(\\omega_0)}{1+sin(\\omega_0)}
        \\end{aligned}
        `}</BlockMath>


        
        proof for the last step: {" "}
        <CollapsiblePart>
        Let:
        <ul>
          <li><InlineMath math="c = cos(\frac{a}{2})"/></li>
          <li><InlineMath math="s = sin(\frac{a}{2})"/></li>
          <li><InlineMath math="t = tan(\frac{a}{2})"/></li>
        </ul>
        Then:
        <BlockMath>{`
        \\begin{aligned}
        \\frac{1-tan(\\frac{a}{2})}{1+tan(\\frac{a}{2})} &= \\frac{1-t}{1+t} \\\\
                                                         &= \\frac{1-t^2}{(1+t)^2} \\\\
                                                         &= \\frac{1-\\frac{s^2}{c^2}}{1+2t + t^2} \\\\
                                                         &= \\frac{1-\\frac{s^2}{c^2}}{1+2\\frac{s}{c} + \\frac{s^2}{c^2}} \\\\
                                                         &= \\frac{c^2-s^2}{c^2+2sc+ s^2} \\\\
                                                         &= \\frac{cos(a)}{1+2sc} \\\\
        \\frac{1-tan(\\frac{a}{2})}{1+tan(\\frac{a}{2})} &= \\frac{cos(a)}{1+sin(a)} \\\\
          
        \\end{aligned}
        `}</BlockMath>

        </CollapsiblePart>

        Summing up, we have :

        <BlockMath>{`
        \\begin{aligned}
        y_n &= a_0 x_n + a_1 x_{n-1} - b_1 y_{n-1} \\\\
        a_0 = a_1 &= \\frac{1-\\gamma}{2} \\\\
        b_1 &= -\\gamma \\\\
        \\gamma &= \\frac{cos(\\omega_0)}{1+sin(\\omega_0)} \\\\
        \\omega_0 &= \\omega_c \\cdot T \\\\
        \\end{aligned}
        `}</BlockMath>

        <H3 id="better-lpf-implementation">Implementation of a better first order lpf</H3>
        <CodeBlock>{betterLpf}</CodeBlock>

        As before, this is a non-optimized code, just to test the algorithm.

        <H3 id="better-lpf-results">Results</H3>

        <ImagesViewer imagePath={bodeBetterLpf} 
          alt="Frequency response of a better implementation of a first order low-pass filter" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
        Bode plot of the improved low pass filter we implemented in the last section
        <br />The ideal curve is superimposed in white.
        <br />The cutoff frequency is still 100Hz</div>
        <br />
        Overall, this is far better than the previous implementation. The phase is almost perfect. 
        We just have a drop in the gain near the highest frequencies.

        <ImagesViewer imagePath={bodeBetterLpfDetail} 
          alt="Frequency response of a better implementation of a first order low-pass filter" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
        Detail of the bode plot of the improved low pass filter, with the same zoom we used for the simple implementation
        <br />The ideal curve is superimposed in white.
        <br />The cutoff frequency is still 100Hz</div>

        <br />The error is around the precision of c++'s <InlineCode>double</InlineCode>, we can't really hope for a better precision.

        {/* From {cite.full("pirkle_2019")}, we get :
        

        Useful equations :
        <BlockMath>{"\\frac{cos(x)}{1+sin(x)} = -tan(\\frac{x}{2} - \\frac{\\pi}{4}) = \\frac{1-tan(\\frac{x}{2})}{1+tan(\\frac{x}{2})}"}</BlockMath> */}

        <H2 id="simple-mlf">Simple implementation of a Moog filter</H2>
        
        <H3 id="simple_mlf-theory">Quick reminder of the principle</H3>
        The filter's structure is described on the following drawing, with some new notation introduced:
        <ImagesViewer imagePath={filterDiscreteStructure} alt="Block diagram of the Moog ladder filter" 
          style={{width:'80%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Structure of the Moog ladder with discrete notation</div>
        <br />
        Let <InlineMath math="x_{i,n}"/> be the input of the filter <InlineMath math="i"/>. 
        From the previous section, we have:
        <BlockMath>{`y_{i,n} = a_0\\cdot x_{i,n} + a_1\\cdot x_{i,n-1} + b_1\\cdot y_{i,n-1}`}</BlockMath>
        So we can compute the output of the fourth filter based on the output of the third one and so on,
        but to compute the input of the first filter (<InlineMath math="u_n = x_n - k\cdot y_n"/>),
        we have to already know the output of the fourth filter. In other words, to compute {" "}
        <InlineMath math="y_n"/>, we have to know <InlineMath math="y_n"/> beforehand. 
        We will show in the next section a way to achieve that. For now, a first idea to solve this problem
        is to use <InlineMath math="y_{n-1}"/> as a substitute to <InlineMath math="y_n"/>.
                
        <H3 id="simple_mlf-implementation">Implementation</H3>
        <CodeBlock>{mlfWithDelay}</CodeBlock>
        Again, this is not optimized code, you wouldn't want to run it in a real-time application.
        It is here for readability and testing the algorithm.
        <H3 id="simple_mlf-results">Results</H3>
        Let's display the ideal result and what this implementation gives us :
        <ImagesViewer imagePath={idealMoogBode} alt="Bode plot for the Moog ladder filter" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Ideal bode plot for the filter. The cutoff frequency is 100 Hz</div>
        
        <ImagesViewer imagePath={delayMoogBode} alt="Bode plot for the Moog ladder filter" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Bode plot for our filter's implementation. The cutoff frequency is 100 Hz</div>

        It doesn't look so bad as long as <InlineMath math="K <= 3"/>. 
        Let's try and listen what the output is.

        <br />We will be filtering a pure sawtooth wave at 100Hz, with a sample rate of 44100Hz. 
        Here's what the raw signal sounds like :
        <div style={{marginTop: "1em", marginBottom:"1em"}}>
        <ReactAudioPlayer
          src={raw_sawtooth}
          controls
        />
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Raw sawtooth at 100Hz</div>
        </div>

        We will perform a linear cutoff frequency sweep from 20Hz to 10 000Hz. 
        Let's test our filter with <InlineMath math="K=0"/>.

        <div style={{marginTop: "1em", marginBottom:"1em"}}>
        <ReactAudioPlayer
          src={sound_delay_K_0}
          controls
        />
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Filtered sawtooth <br /> Linear cutoff frequency sweep from <InlineMath math="f_c = 20\mathrm{~to~} 10~000~\mathrm{Hz}"/> 
          <br /> <InlineMath math="k=0"/></div>
        </div>

        Seems alright, it sounds like what I expected. 
        There is no resonance because the feedback <InlineMath math="k"/> is at 0.
        Let's try the filter with <InlineMath math="k=1"/>.

        <div style={{marginTop: "1em", marginBottom:"1em"}}>
        <ReactAudioPlayer
          src={sound_delay_K_1}
          controls
        />
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Filtered sawtooth <br /> Same frequency sweep.
          <br /> <InlineMath math="k=1"/></div>
        </div>
        It starts to sound like a resonant filter, let's push it with <InlineMath math="k=2"/>
        <div style={{marginTop: "1em", marginBottom:"1em"}}>
        <ReactAudioPlayer
          src={sound_delay_K_2}
          controls
        />
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Filtered sawtooth <br /> Same frequency sweep.
          <br /> <InlineMath math="k=2"/></div>
        </div>

        Well, something is going wrong at higher <InlineMath math="f_c"/>, 
        let's hear what <InlineMath math="k=3"/> sound like: 
        <div style={{marginTop: "1em", marginBottom:"1em"}}>
        <ReactAudioPlayer
          src={sound_delay_K_3}
          controls
        />
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Filtered sawtooth <br /> Same frequency sweep.
          <br /> <InlineMath math="k=3"/></div>
        </div>
        The amplitude seems to be too high and clips outside of the range of the file.
        For comparison, here's what the filter in the next section can produce:
        <div style={{marginTop: "1em", marginBottom:"1em"}}>
        <ReactAudioPlayer
          src={sound_filtered_K_3_9}
          controls
        />
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Filtered sawtooth with a better filter<br /> Same frequency sweep.
          <br /> <InlineMath math="k=3.9"/></div>
        </div>
        We can clearly hear the self oscillation and there is no amplitude issue.

        <br />So what's happening in our naïve implementation ?
        For a first clue, let's plot the waveform produced at <InlineMath math="k=3"/>: 
        
        <br />
        [ideas]
        <br />Plot the waveform : not really telling anything.
        
        <ImagesViewer imagePath={animationMlfDelay} alt="Bode plot for the Moog ladder filter" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Bode plot for our filter's implementation at <InlineMath math="k=3"/>. 
          <br />Logarithmic cutoff frequency sweep from 10 to 10 000 Hz
          </div>
        <br />
        Here we can see that the filter's gain is too high when <InlineMath math="f_c>2000~\mathrm{Hz}"/>.

        Note that contrarily to the audio examples, this is a logarithmic sweep, 
        so the issue we hear quickly in the audio comes at the end of the animation.
          
        <br />Is it a problem of stability ? investigate it
        <br />I mean, yeah it's a stability issue, but I don't know how to compute the Z transform
        of this implementation.
        <br /><span style={{color:"red"}}>Are there other ways to prove the instability of a numerical filter ?</span>
       
        
        <H2 id="delay-free-implementation">Delay-free implementation</H2>

        <H3 id="refactoring">Refactoring</H3>

        Ideally, we would like to have a delay-free implementation, ie. we would like our filter's equation to look like this :

        <BlockMath>{"y_n = f(x_n) + \\sum_i g_i(s_i)"}</BlockMath>

        where <InlineMath math="s_i"/> would be all the delayed samples (the samples computed in the previous loop). 
        This way, we could compute the filter in one pass, getting rid of the delay from the previous paragraph.

        <br /> Let's try and refactor our equation to achieve this.
        <br />
        <br />[Note] This section is heavily inspired by {cite.full("pirkle_2019")}'s <span style={{fontStyle:"italic"}}>Modified Härmä method</span>
        {" "}which seems to be described extensively in "Designing Software Synthesizers in C++", but I don't have a copy of that book, 
        so I will try and give my own explanation to get the same results.
        <br />
        <br />
        Generally speaking, our low-pass filters' implementations will look like this:
        
        <BlockMath>{`\\begin{aligned}y_n = a_0 x_n &+ a_1 x_{n-1} + a_2 x_{n-2} + ... \\\\ 
                                                   &+ b_1 y_{n-1} + b_2 y_{n-2} + ... \\end{aligned}`}</BlockMath>

        where <InlineMath math="y_n"/> is the output of the filter at a discrete time <InlineMath math="t = nT"/> {" "}
        and <InlineMath math="x_n"/> is the input of the filter at <InlineMath math="nT"/>. 
        The coefficients <InlineMath math="a_i" /> and <InlineMath math="b_i" /> vary only with <InlineMath math="f_c" /> {" "}
        and do not depend on the input. Moreover, the values <InlineMath math="x_i"/> and {" "}
        <InlineMath math="y_i"/> for <InlineMath math="i<n"/> ("delayed parts") only depend on previous states of the filter
        and can be remembered in the implementation.
        
        
        
        So the filter's equation can be refactored:
        <BlockMath>{"y_n = a_0 x_n + D_n"}</BlockMath>

        where <InlineMath math="D_n"/> contains all the delayed parts. Let's bring back our block diagram from before :

        <ImagesViewer imagePath={filterDiscreteStructure} alt="Block diagram of the Moog ladder filter" 
          style={{width:'80%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Structure of the Moog ladder with discrete notation</div>

        
        <br />If we can compute <InlineMath math="u_n"/> in a way that does not rely on knowing <InlineMath math="y_n"/> beforehand, 
        we can compute <InlineMath math="u_n"/>, then the effect of the four filters and get <InlineMath math="y_n"/> delay-free.
        <br />The output of the first filter is :
        <BlockMath>{"y_{1,n} = a_0 u_n + D_{1,n}"}</BlockMath>
        Second filter : 
        <BlockMath>{`\\begin{aligned}y_{2,n} &= a_0 y_{1,n} + D_{2,n} \\\\
                                             &= a_0^2 u_n + a_0 D_{1,n} + D_{2,n}
        \\end{aligned}`}</BlockMath>
        <br />So the output of the filter is :
        <BlockMath>{`\\begin{aligned}y_n = y_{4,n} &= a_0 y_{3,n} + D_{4,n} \\\\
                                             &= a_0^4 u_n + a_0^3 D_{1,n} + a_0^2 D_{2,n} + a_0 D_{3,n} + D_{4,n} \\\\
                                         y_n &= a_0^4 u_n + \\sum_{i=1}^4 \\gamma_i D_{i,n}
        \\end{aligned}`}</BlockMath>

        where <InlineMath math="\gamma_1 = a_0^3"/>, <InlineMath math="\gamma_2 = a_0^2"/>, {" "}
        <InlineMath math="\gamma_3 = a_0"/> and <InlineMath math="\gamma_4 = 1"/>.

        <br />
        Let's try and isolate <InlineMath math="u_n"/>:
        <BlockMath>{`\\begin{aligned}u_n &= x_n - k \\cdot y_n \\\\
                                         &= x_n - k \\cdot a_0^4 u_n - k \\cdot\\sum_i \\gamma_i D_{i,n} \\\\
                   u_n (1+k\\cdot a_0^4) &= x_n - k \\cdot\\sum_i \\gamma_i D_{i,n} \\\\
                                     u_n &= \\frac{1}{1+k\\cdot a_0^4}(x_n - k \\cdot\\sum_i \\gamma_i D_{i,n})\\\\
                                     u_n &= \\alpha \\cdot (x_n - k \\cdot\\sum_i \\gamma_i D_{i,n})
        \\end{aligned}`}</BlockMath>

        where <InlineMath math="\alpha = \frac{1}{1+k\cdot a_0^4}"/>.
        <br />So now we can compute <InlineMath math="u_n"/> without knowing <InlineMath math="y_n"/>. 
        What we did can be represented with the following block diagram:

        <ImagesViewer imagePath={filterDiscreteStructureDelayFree} alt="Equivalent block diagram of the refactoring we did above" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
        Equivalent block diagram of the refactoring we did above <br />This diagram is a redrawing of one from {cite.full("pirkle_2019")} </div>

        <br />Each low-pass filter is equivalent to the following structure :

        <ImagesViewer imagePath={lpfEquivalent} alt="Equivalent block diagram of each first order low-pass filter" 
          style={{width:'40%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
        Equivalent block diagram of each first order low-pass filter</div>

        <br />where <InlineMath math="D_{i,n}"/> contains all the delayed parts.


        <H3 id="delay-free-implementation">Implementation</H3>
        <CodeBlock>{mlfDelayFree}</CodeBlock>
        Same comment as before, this is unrefined code, 
        don't use it in a real app.
        <H3 id="delay-free-implementation-results">Results</H3>
        Let's view the Bode plot of our new filter:
        <ImagesViewer imagePath={bodeDelayFreeMlf} alt="Bode plot for the last implementation of the Moog ladder filter" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Bode plot for our better filter's implementation.
          <br/>As before, the samplerate is 44100 Hz 
          and <InlineMath math="f_c=100~\mathrm{Hz}"/>
          </div> 
        <br />This is somewhat better, but there is still some strange behavior for {" "}
        <InlineMath math="k=3.96"/>. The peak at <InlineMath math="f_c"/> is better 
        (ie. closer to the model), but the amplitude at higher frequencies is strange.
        Let's check if there is still issues with {" "}
        <InlineMath math="k=3"/>:       
        <ImagesViewer imagePath={animationMlfDelayFreeK3} alt="Bode plot for the Moog ladder filter" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Bode plot for our better filter's implementation at <InlineMath math="k=3"/>. 
          <br />Logarithmic cutoff frequency sweep from 10 to 10 000 Hz
          </div> 
        <br />It's far better than the previous implementation, it seems that we got 
        rid of the issue with <InlineMath math="k=3"/>. Let's check {" "}
        <InlineMath math="k=3.96"/>:
        <ImagesViewer imagePath={animationMlfDelayFreeK3_96} alt="Bode plot for the Moog ladder filter" 
          style={{width:'90%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Bode plot for our better filter's implementation at <InlineMath math="k=3.96"/>. 
          <br />Logarithmic cutoff frequency sweep from 10 to 10 000 Hz
          </div>
        <br />
        The phase shows more agitation, but the amplitude seems overall stable, 
        this is a good sign for this implementation.
        <br />
        Let's hear what it sounds like at <InlineMath math="k=3"/>, 
        with the same setup as before (ie. filtered 100Hz sawtooth, 
        samplerate of 44 100 Hz, linear cutoff frequency from 10 to 10 000 Hz): 
        <div style={{marginTop: "1em", marginBottom:"1em"}}>
        <ReactAudioPlayer
          src={sound_filtered_K_3}
          controls
        />
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Filtered sawtooth with the better filter's implementation 
          <br /> Same frequency sweep.
          <br /> <InlineMath math="k=3"/></div>
        </div>  
        This is very promising, it seems like we got rid of the filter instability. 
        Let's try it with <InlineMath math="k=3.9"/> : 
        <div style={{marginTop: "1em", marginBottom:"1em"}}>
        <ReactAudioPlayer
          src={sound_filtered_K_3_9}
          controls
        />
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Filtered sawtooth with the better filter's implementation 
          <br /> Same frequency sweep.
          <br /> <InlineMath math="k=3.9"/></div>
        </div>  
        Again, this seems good. We can hear the self-oscillation of the filter,
        which is not overwhelming the signal because 
        we're not quite at the maximum for <InlineMath math="k"/>. 
        However, there is still a problem for <InlineMath math="k=4"/>.
        I can't put here the raw file, but I can put a version with reduced volume:
        <div style={{marginTop: "1em", marginBottom:"1em"}}>
        <ReactAudioPlayer
          src={sound_filtered_K_4}
          controls
        />
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Filtered sawtooth with the better filter's implementation 
          <br /> Same frequency sweep.
          <br /> <InlineMath math="k=4"/></div>
        </div>
        This fun, but not at all what we want our filter to sound like. 
        The output of the filter is saturating. But this time it is expected: we're
        implementing an idealized version of the filter, and 
        our ideal filter's equation is undefined for <InlineMath math="k=4"/> and {" "}
        <InlineMath math="\omega=\omega_c"/>:
        <BlockMath>{`
        \\begin{aligned}
            H(\\omega) &= \\frac{1}{k + \\left(1 + i\\frac{\\omega}{\\omega_c}\\right)^4} \\\\
                       &= \\frac{1}{4 + \\left(1 + i\\right)^4} \\\\ 
                       &= \\frac{1}{4 + \\left(1 + 2i - 1\\right) ^2} \\\\ 
                       &= \\frac{1}{4 - 4} \\\\ 
        \\end{aligned}
        `}</BlockMath>
        A solution is to create a limiter that prevents 
        the output of our filter to go too high. 
        We will see an implementation of this in the next section.
        <H2 id="audio-limiter">Audio limiter</H2>
        <H3 id="audio-limiter-theory">How does an audio limiter work?</H3>
        An audio limiter needs to limit the amplitude of a signal to a threshold value.
        The first step is to get the amplitude of a signal, then decide on a method to limit it.
        <H4 id="envelope-detection">Envelope detection</H4>
        The amplitude of the signal will be called the envelope of the signal. To recover the envelope of the signal,
        we will use the principle of an analog <InlineMath math="RC"/> envelope follower.
        <ImagesViewer imagePath={rcFilter} alt="Schematics of a simple 
        RC filter as an envelope follower" 
          style={{width:'35%', marginTop:"1em"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          RC envelope follower
          </div>  
        <br />

        <br />Why do we have a different time response between rise
        and fall ?

        <H4 id="AD-threshold">Threshold (temp title)</H4>
        inside knee:
        <BlockMath>{`
            y_{dB} = env_{dB} - \\frac{\\left(env_{dB} - thr_{dB} + \\frac{kneeWidth_{dB}}{2}\\right)^2}{2 * kneeWidth_{dB}}
        `}</BlockMath>
        <H3 id="audio-limiter-implementation">Implementation</H3>
        <H3 id="audio-limiter-results">Results</H3>
        <div style={{marginTop: "1em", marginBottom:"1em"}}> 
        <ReactAudioPlayer
          src={sound_filtered_K_AD_0}
          controls
        />
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Filtered sawtooth with the better filter's implementation and audio limiter 
          <br /> Same frequency sweep.
          <br /> <InlineMath math="k=0"/></div>
        </div>         <div style={{marginTop: "1em", marginBottom:"1em"}}> 
        <ReactAudioPlayer
          src={sound_filtered_K_AD_1}
          controls
        />
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Filtered sawtooth with the better filter's implementation and audio limiter 
          <br /> Same frequency sweep.
          <br /> <InlineMath math="k=1"/></div>
        </div>         <div style={{marginTop: "1em", marginBottom:"1em"}}> 
        <ReactAudioPlayer
          src={sound_filtered_K_AD_2}
          controls
        />
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Filtered sawtooth with the better filter's implementation and audio limiter 
          <br /> Same frequency sweep.
          <br /> <InlineMath math="k=2"/></div>
        </div>  
        <div style={{marginTop: "1em", marginBottom:"1em"}}> 
        <ReactAudioPlayer
          src={sound_filtered_K_AD_3}
          controls
        />
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Filtered sawtooth with the better filter's implementation and audio limiter 
          <br /> Same frequency sweep.
          <br /> <InlineMath math="k=3"/></div>
        </div>    
        <div style={{marginTop: "1em", marginBottom:"1em"}}> 
        <ReactAudioPlayer
          src={sound_filtered_K_AD_3_9}
          controls
        />
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Filtered sawtooth with the better filter's implementation and audio limiter 
          <br /> Same frequency sweep.
          <br /> <InlineMath math="k=3.96"/></div>
        </div>
        <div style={{marginTop: "1em", marginBottom:"1em"}}>
        <ReactAudioPlayer
          src={sound_filtered_K_AD_4}
          controls
        />
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          Filtered sawtooth with the better filter's implementation and audio limiter 
          <br /> Same frequency sweep.
          <br /> <InlineMath math="k=4"/></div>
        </div>  


        <H2 id="results">Final implementation and results</H2>
        <H2 id="bibliography">Bibliography</H2>
        {cite.bibliography()} 
      </>
    );
  }
}

const MoogFilter:Article = {
  title:"Designing a Moog style filter",
  abstract: "How to implement a digital filter which mimics the sound of the classical Moog ladder filter",
  link:"/articles/2022/02/25/Moog_filter",
  content: <Content/>,
  date:"2022:02:25:23:15",
}

export default MoogFilter;
