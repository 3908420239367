
import {Component} from "react"

class ArticleTitle extends Component <any, {}>{
  render() {

    return (
      <>

        <div style={{ backgroundImage:`url(${this.props.backgroundImage})`,backgroundRepeat:"no-repeat",
        backgroundSize: "cover",
        minHeight: "10em",
        // paddingLeft: "1em",
        backgroundBlendMode: "darken",
        backgroundColor: "#282c3499",
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        }}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            margin: "1em",
            
          }}>
            <h2 className="nocount" id="title" style={{marginTop: 0}}>{this.props.title}</h2>
            <div>{this.props.children}</div>
          </div>
        </div>
        <br />

      </>
    );
  }
}


export default ArticleTitle;