import {
  // Component, 
  // createRef, 
  // KeyboardEvent, 
  useState, useEffect} from "react"


const useHeadingsData = (hHeading:string) => {
  const [nestedHeadings, setNestedHeadings] = useState([Element]);

  let selector = "h" + parseInt(hHeading).toString() +", h" + (parseInt(hHeading)+1).toString() + ", h" + (parseInt(hHeading)+2).toString();

  useEffect(() => {
    const headingElements = Array.from(
      document.querySelectorAll(selector)
    );

    

    const newNestedHeadings = getNestedHeadings(headingElements, hHeading);
    setNestedHeadings(newNestedHeadings);
  }, []);

  return { nestedHeadings };
};

const getNestedHeadings = (headingElements:Array<Element>, hHeading:string) => {
  const nestedHeadings:Array<any> = [];

  let h0 = "H" + hHeading;
  let h1 = "H" + (parseInt(hHeading)+1).toString();
  console.log("h1 = ", h1)
  let h2 = "H" + (parseInt(hHeading)+2).toString();

  let c0=0, c1=0, c2=0;

  // for (let index = 0; index < headingElements.length; index++) {
  //   let element:any = headingElements[index];
    
    
  // }

  headingElements.forEach((heading:any, index:number) => {
    let { innerText: title, id } = heading;

    let n = "";
    if(!heading.classList.contains("nocount")){
      if(heading.nodeName === h0){
        c0 +=1
        c1 = 0;
        c2 = 0;
        n = c0.toString() + ".";      
      }else if(heading.nodeName === h1){
        c1 +=1;
        c2 = 0;
        n = c0.toString() + "." + c1.toString() + "."; 
      }else if(heading.nodeName === h2){
        c2 +=1;
        n = c0.toString() + "." + c1.toString() + "." + c2.toString() + "."; 
      }

      // title = n + " " + title;

      // element.innerText = 
    }

    if (heading.nodeName === h0 && heading.id !== "title") {
      nestedHeadings.push({ id, title, items: [], n });
    } else if (heading.nodeName === h1 && nestedHeadings.length > 0) {
      nestedHeadings[nestedHeadings.length - 1].items.push({
        id,
        title,
        items:[],
        n,
      });
    }else if (heading.nodeName === h2 && nestedHeadings.length > 0) {
      let h5length = nestedHeadings[nestedHeadings.length - 1].items.length;
      // console.log("h5length = ", h5length);
      if(h5length > 0){
        nestedHeadings[nestedHeadings.length - 1].items[h5length -1].items.push({
          id,
          title,
          n,
        });
      }
    }
  });

  return nestedHeadings;
};

const Headings = ({ headings }:any) => (
  <ul style={{marginBottom:"0px"}}>
    {headings.map((heading:any) => (
      <li key={heading.id}>
        <a className="link-nostyle" href={`#${heading.id}`}>{heading.title}</a>
        {heading.items.length > 0 && (          
          <ul>
            {heading.items.map((child:any) => (
              <li key={child.id}>
                <a className="link-nostyle" href={`#${child.id}`}>{child.title}</a>
                  <ul>
                    {child.items.map((grandChild:any) => (
                      <li key={grandChild.id}>
                        <a className="link-nostyle" href={`#${grandChild.id}`}>{grandChild.title}</a>
                      </li>
                    ))}
                  </ul>
              </li>            
            ))}
          </ul>
        )}
      </li>
    ))}
  </ul>
);

const TableOfContents = (props:any) => {

  let hHeading = "4";
  if(props.highestHeading){
    hHeading = props.highestHeading
  }

  const { nestedHeadings } = useHeadingsData(hHeading);
  console.log("nestedHeadings : ", nestedHeadings)
  if(nestedHeadings.length > 1){
    return (
      <nav aria-label="Table of contents" className={"toc-content " + props.collapseClassName}>
        <Headings headings={nestedHeadings} />    
      </nav>
    );
  }else{
    return(<></>);
  }
};

const CollapsibleTableOfContents = (props:any)=>{ 
  
  // showShow:boolean=false;
  // setShowShow = (a:boolean)=>{};
  // toggleShow = ()=>{};
  
  // constructor(props:any){
  //   super(props);
  //   [this.showShow, this.setShowShow] = useState(Boolean);

  //   this.toggleShow = () => this.setShowShow(!this.showShow);
  // }
  const [showShow, setShowShow] = useState(Boolean);
  const toggleShow = () => setShowShow(!showShow);
  const toggleCollapse = ()=>{
    // console.log("toggling");
    toggleShow();
    
  }
  return(
    <div style={{fontSize:"smaller", transition:"height 300ms cubic-bezier(.4, 0, .2, 1)"}}>
      <br />
      <span onClick={toggleCollapse} >Table of contents {showShow ? "-" : "+"}</span>
      <TableOfContents highestHeading={props.highestHeading} collapseClassName={showShow?"":"collapsed"}/>
    </div>);
}

export {
  CollapsibleTableOfContents,
  TableOfContents,
}