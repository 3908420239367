// import Watch from "./2021/11/19_Watch"
import AP04 from "./2021/11/20_AP-04";
import Scharm from "./2022/01/31_Scharm"
import MoogFilter from "./2022/02/25_Moog_filter";
import ProjectsTimeline from "./2022/11/06_Projects_timeline"
// import test from "./2022/test/test"
// import SampleArticle from "./SampleArticle";

const ArticleListProd = [
  // Watch,
  AP04,

]

const ArticleListDev = [
  Scharm,
  MoogFilter,
  ProjectsTimeline,
  // test,
]

let ArticleList = ArticleListProd
if(process.env.REACT_APP_ENV === "dev"){
  ArticleList = ArticleList.concat(ArticleListDev);
}



export default ArticleList;