// import React from 'react';
import * as React from 'react'
// import logo from './logo.svg';
import './App.css';
import { Col, Row } from "reactstrap";
import Header from "./components/template/Header";
import {Component} from "react"

import {BrowserRouter, Route, 
  Routes, 
  // Link
} from "react-router-dom";
// import SampleArticle from './contents/articles/SampleArticle';
import ArticlesSummary from './contents/ArticlesSummary';

import Articles from "./contents/Articles"

// import Test from "./components/Test";

function About(){
  return (
    <p className="single-line-content">Site created using React.js, served by express, hosted by nginx on a Ubuntu server.</p>
  );
};

function Home(){
  return (<p className="single-line-content">
  Site under construction.
</p>);
};

function News(){
  return (
    <p className="single-line-content">I am writing a few articles to put some content on this website.
    <br />Next article published will be on an implementation of a popular low-pass filter.
    </p>
  );
};




class App extends Component {
  componentDidUpdate(){
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if(scrollPosition){
      window.scrollTo(0, parseInt(scrollPosition));
      console.log("App scrolling to ", scrollPosition);
      sessionStorage.removeItem("scrollPosition");
    }else{
      sessionStorage.setItem("scrollPosition", (window.scrollY).toString());
    }
  }
  render(){
    console.log("CONSOLE OUTPUT")
    return (
      <div className="App">
      {/* <> */}
        <header className="App-header">
        <link
            href="//cdnjs.cloudflare.com/ajax/libs/KaTeX/0.9.0/katex.min.css"
            rel="stylesheet"
        />
        <Row>
          <Col>
          {/* <BrowserRouter> */}
            <Header></Header>
            {/* </BrowserRouter> */}
          </Col>
        </Row>
        </header>
        
        <div className="App-body">
          <div className="body-content">
          
            <BrowserRouter>
                <div className="main-content">
                
                  <Routes>
                  
                    <Route path="/" element={<Home />}/>
                    <Route path="/about" element={<About />}/>
                    <Route path="/news" element={<News />}/>
                    {/* <Route path="/articles" element={<SampleArticle />}/> */}
                    <Route path="/articles" element={<ArticlesSummary />}/>
                    <Route path="/articles/*" element={<Articles />}/>
                  </Routes>
                </div>
            </BrowserRouter>
          </div>
        </div>
      {/* </> */}
      </div>
    );
  }
}

export default App;
