import {Component, 
  // createRef, 
  // KeyboardEvent, 
  // useState, 
  // useEffect
} from "react"
import clamp from "../utils/clamp"

class ScrollToTop extends Component <{}, {}>{
  state = {
    opacity: this.computeOpacity()
    // opacity: "0"
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      window.onscroll = () => {
        
        let currentScrollPos = window.pageYOffset;
        // console.log(maxScroll)
        if (currentScrollPos > 0) {
          this.setState({ opacity: this.computeOpacity() })

        } else {
          this.setState({ opacity: "0" })
        }
      }
    }
  }
  computeOpacity(){
    if (typeof window !== "undefined") {
      let ramp = 0.5;
      let currentScrollPos = window.pageYOffset;
      let minScroll = window.innerHeight;
      return clamp((currentScrollPos - minScroll/3) * ramp / minScroll, 0, 1).toString()
    }else{
      return "1";
    }
  }
  render() {
    return (
      <div onClick={()=>{window.scrollTo({top: 0, left: 0, behavior: "smooth"});}} 
        className="scrollTop nonDrag" 
        draggable="false" 
        onDragStart={()=>{}}
        style={{ opacity: `${this.state.opacity}`}}
        />)
  }
}

export default ScrollToTop;