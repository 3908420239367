
// import { LoremIpsum } from 'react-lorem-ipsum';
import "./ArticlesSummary.css"

import ArticleList from './articles/articlesList';


// import { AiOutlineUser } from "react-icons/ai";

const articleList = [
  ...ArticleList,
  // {title: "first article", date: "0", abstract: "some short description ", link: "/articles/test"},
  // {title: "second article", date: "0", abstract: "some short description ", link: "/"},
  // {title: "third article", date: "0", abstract: "some short description ", link: "/"},
  // {title: "fourth article", date: "0", abstract: "some short description ", link: "/"},
]

const ArticlesSummary = () => {
  return (
    // <div className="articles">
    <div className="articlesSummary">
    <h2>Articles</h2>

      <div className="articlesList">
      {
        articleList.map(function(article){
          return (
            <div className="alItem">
            <a className="alTitle link-nostyle" href={article.link}>{article.title}</a>
            <a className="alAbstract" href={article.link}>{article.abstract} 
            {/* Lorem ipsum odor amet, consectetuer adipiscing elit. Tempor tempus suscipit blandit dolor, commodo mus tristique. Dis efficitur facilisis hendrerit augue senectus tempor semper. Placerat cras sapien maecenas dui arcu donec erat. Varius felis malesuada faucibus per adipiscing nam condimentum. Sapien massa fames eget, magna eget cubilia. Sociosqu rhoncus dictum diam vulputate sodales scelerisque. */}
            {/* <span className="hiddenLink"><a href={article.link}/></span> */}
            </a>
            </div>
            
          )
        })

      }
      </div>
      <div>
      ... That's all for now
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      </div>
    </div>
  );
};
export default ArticlesSummary;