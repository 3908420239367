import SyntaxHighlighter from 'react-syntax-highlighter';
import {vs2015} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {Component, createRef, KeyboardEvent, useState, useEffect} from "react"

// images
import sharm_gui_v2 from "./scharm_img/SharmGuiv2.png"
import sharm_gui_v1 from "./scharm_img/SharmGuiv1.png"
import scharm_gui_latest from "./scharm_img/ScharmGui_latest.png"

import {CollapsibleTableOfContents} from "../../../../components/Toc";
import ScrollToTop from '../../../../components/ScrollToTop';
import ArticleTitle from '../../../../components/ArticleTitle';
import {ImagesViewer} from "../../../../components/ImagesViewer";
import {InlineCode} from "../../../../components/Code";

class Content extends Component <any, {}>{
  render() {
    return (
      <>
        
        <ArticleTitle backgroundImage={sharm_gui_v2} title="Scharm">
        A software synthesizer based on negative harmonics and polyrhythms
            <br/>Written in c++, with midi
        </ArticleTitle>
        <br />
        [NOTE] Writing in progress
        <CollapsibleTableOfContents/>
        <ScrollToTop/>
        <h4 id="motivation">Motivation</h4>
        The main motivation for building this synth was to be able to play with the ideas 
        the <a className="link-ul" href="https://www.moogmusic.com/products/subharmonicon">Moog Subharmonicon</a>
        {" "}synth was bringing (specifically, the negative harmonics and polyrhythms)
        without having to buy this expensive instrument. 
        I build a first version in python as a challenge (the challenge being to try and 
        implement a complex sound engine in a language that is not designed for such a task).
        I was mostly successful, but a bit frustrated because python's speed was limiting my ability 
        to implement features on the sound engine that would have been quite easy to write 
        in a faster language. As an example, I spent quite a long time finding a quick algorithm 
        for a resonant filter which could have been done in a simple C++ loop.

        <ImagesViewer imagePath={scharm_gui_latest} alt="Latest version of the project's interface" 
          style={{width:'50%', marginTop:"0em"}}
          caption="Latest version of Scharm's interface"/>

        <br />With that in mind, I started this new and improved version. 
        The goal was to port the python project (named "Sharm") in C++ (and renaming it "Scharm", 
        like "Sharm" + the 'c' from C++, see what I did there?)
        and modify the filter to emulate more accurately the classic Moog ladder filter, and to improve 
        the oscillators' algorithms. 
        <br />This took me in a rabbit hole and I wrote {" "}
        <a className="link-ul" href="/articles/2022/02/25/Moog_filter">
        another article dedicated to the Moog filter</a>.
        <br />[And maybe another one on oscillators ?]
        
        <br />
        A side goal was to have a base on which to build other synth more easily 
        (like {" "}
        <a className="link-ul" href="https://www.moogmusic.com/products/dfam-drummer-another-mother">
        this other Moog synth</a>, or {" "}
        <a className="link-ul" href="https://www.korg.com/fr/products/dj/volca_modular/">
        a different style of synthesizer</a>).
        
        <h4 id="interface">User interface</h4>
        The first version of the interface was designed with Tkinter. 
        But I soon discovered that while it may be suited for simple apps, it was too limited for my goals.
        The most frustrating thing was the lack of anti-aliasing, which made the interface unappealing.
        <ImagesViewer imagePath={sharm_gui_v1} alt="First version of the project's interface in python" 
          style={{width:'50%', marginTop:"0em"}}
          caption="Sharm interface with Tkinter"/>

        With a little bit of research, I chose the Qt library to write a new interface. 
        I switched the interface to dark mode, for a more professional look.
        <ImagesViewer imagePath={sharm_gui_v2} alt="Second version of the project's interface, still in python" 
          style={{width:'50%', marginTop:"0em"}}
          caption="Sharm (ie. python version) interface with Tkinter"/>

        Qt is a much more capable library, but the documentation for creating custom widgets 
        is a bit hard to find. Once I found out how to implement custom <InlineCode>paintEvent</InlineCode>,
        I reproduced the design of my knobs from the previous interface. 
        About everything in the new interface is custom widgets. The buttons are derived from Qt's {" "}
        <InlineCode>QAbstractButton</InlineCode>, the little slider to the left of the big "Vco1" knob
        is a modified <InlineCode>QSlider</InlineCode>, the "Quantize" and "Range" controls are derived
        from <InlineCode>QRadioButton</InlineCode> and everything in the patchbay inherits directly
        from <InlineCode>QWidget</InlineCode>, etc.

        <br />
        Switching to C++ was relatively easy, as {" "}<a className="link-ul"
        href="https://www.qt.io/product/qt6/qml-book/ch17-qtcpp-qtcpp">
        Qt is primarily a C++ toolkit</a>. 
        I added theme management to the interface.
        Right now, it is a bit of a mess, but I plan to improve it later. 
        There are small changes in the final look. The buttons' colors changed a bit
        and the buttons gained a small border, this was done to clarify in which position the buttons 
        are active. The labels under the biggest knobs are also bigger now, mainly for readability.

        <ImagesViewer imagePath={scharm_gui_latest} alt="Latest version of the project's interface" 
          style={{width:'50%', marginTop:"0em"}}
          caption="Latest version of the interface"/>

        Under the hood, the structure of the UI was improved, but it is far from perfect,
        as I am not used to coding UIs. I would like to make specifically the following improvements :
        <ul>
          <li>make it truly resizable (right now some fonts are not resized, the patchpoints' size is fixed)</li>
          <li>the patchbay is still buggy (sometimes a patchcord is lost the interface stalls)</li>
          <li>mouse management in the patchbay could be simpler and more elegant</li>
          <li>fix theme management (it works but is a bit convoluted)</li>
          <li>add menus to save and restore states, change theme</li>
          <li>there really should be a dividing line between the two VCO sections</li>
          <li>ideally, I would like to make it more usable to create new instruments</li>
        </ul>

        
        <h4 id="audiolib">Generating the audio</h4>
        guide on how audioObject is designed and how to use it.
        when is it called, what are CVOutputs and altoutputs ?
        <h5 id="routing">Routing the semi-modular audio modules</h5>
        <h4 id="sound-design">Sound design</h4>
        <h5 id="filter">Filter</h5>
        <h5 id="polyblep">Poly blep</h5>
        <h5 id="better-analog-emulation">Analog emulation ?</h5>

      </>
    );
  }
}

const Scharm:Article = {
  title:"Scharm",
  abstract: "A software synthesizer based on negative harmonics and polyrhythms",
  link:"/articles/2022/01/31/Scharm",
  content: <Content/>,
  date:"2022:01:31:22:17",
}

export default Scharm;