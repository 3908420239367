import {
  Component, 
  // createRef, 
  // KeyboardEvent, 
  // useState, 
  // useEffect
} from "react"

import SyntaxHighlighter from 'react-syntax-highlighter';
import {vs2015} from 'react-syntax-highlighter/dist/esm/styles/hljs';

class CodeBlock extends Component <any, {}>{
  // constructor(props:any){
  //   super(props);
  // }
  render(){
    let language = "c";
    if(this.props.language){
      language = this.props.language
    }
    return (
    <div style={{fontSize:"70%", width:"80vw"}}>
      <SyntaxHighlighter language={language} style={vs2015} showLineNumbers>
        {this.props.children}
      </SyntaxHighlighter>
    </div>
    );
  }
}

class InlineCode extends Component <any, {}>{
  // constructor(props:any){
  //   super(props);
  // }
  render() {
    return(<span style={{fontFamily: "monospace",fontSize:"smaller", ...this.props.style}} >{this.props.children}</span>);
  }
}

class InlineCommand extends Component <any, {}>{
  // constructor(props:any){
  //   super(props);
  // }
  render() {
    return(<InlineCode style={{backgroundColor:"black"}}>{this.props.children}</InlineCode>);
  }
}

export {
  CodeBlock,
  InlineCode,
  InlineCommand,
}