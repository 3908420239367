import SyntaxHighlighter from 'react-syntax-highlighter';
import {vs2015} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {Component, createRef, KeyboardEvent, useState, useEffect, ReactNode} from "react"


import {CollapsibleTableOfContents} from "../../../../components/Toc"
import ScrollToTop from '../../../../components/ScrollToTop';
import ArticleTitle from '../../../../components/ArticleTitle';
import {H2, H3, H4} from '../../../../components/SectionTitles'
import {CodeBlock, InlineCode, InlineCommand} from "../../../../components/Code"

import filterStructure from "../02/img/filter_structure.svg"
import UM10204 from "./doc/UM10204.pdf"


import KaTeX from 'katex';


// import {Document} from "react-pdf"

import {ImagesViewer} from "../../../../components/ImagesViewer"

class Content extends Component <any, {}>{
  render() {
    // let cite = new Citation();
    return (
      <>
        <ArticleTitle backgroundImage={filterStructure} title="Projects Timeline">
          A page where I log my projects ideas and order them.
        </ArticleTitle>

        <br />
        [NOTE] Writing in progress
        <CollapsibleTableOfContents highestHeading="2"/>
        <ScrollToTop/>
        <h2 id="timeline">Timeline</h2>
        <H2 id="introduction">Introduction</H2>
        <H2 id="teensy">Teensy</H2>
          <H3 id="teensy-manyOutputs">Multiple audio outputs</H3>
          With TDM support, see 
          <br /> board : https://oshpark.com/shared_projects/gVFy0fWQ
          <br /> schematics : https://github.com/geedotk/CS42448_T4_TEST2
          <H3 id="teensy-scharm">Use them to create a numeric physical Scharm instrument</H3>
          <H3 id="teensy-volca-modular">Use them to create a numerical physical volca modular</H3>
          this needs some analog circuitry to run some operations (like split and utility)
        <H2 id="Attiny">Attiny</H2>
          <H3 id="attiny-morePots">Connecting more inputs to the Attiny</H3>
          I'm still testing if it is viable to have i2c chips connected to an attiny as well as v-usb.
          <br /><a href={UM10204} className="link-ul"> i2c specs</a>,{" "}
          <a className='link-ul' href="https://en.wikipedia.org/wiki/I%C2%B2C">wiki</a>
          <br />I didn't find a library to run an attiny as i2c master (maybe{" "}
          <a className='link-ul' href="https://github.com/rambo/I2C">this one</a> ?)
          <br />For now, I am writing code to operate attiny as slave, still testing.
          <br />I tested addressing this chip with and arduino running the Wire lib:{" "}
          <a className="link-ul" href="https://www.adafruit.com/product/1427">HT16K33</a>{" "}
          (<a className="link-ul" href="https://learn.adafruit.com/adafruit-led-backpack/downloads">doc</a>)
          <br />It works.
          <br />Now I am trying to talk to the attiny with an arduino as Master, and it is starting to work.
          <br />maybe <a className='link-ul' href="https://github.com/rambo/TinyWire">this lib</a> could help ?
          <H4 id="i2c-multiplexer-switch">i2c multiplexer switch</H4>
          search "i2c 8 Channel CI commutateur multiplexeur" on mouser
          <br />search: i2c multiplexer switch
            <br />number of channels=8 Channel
            <br />OrgTerm=commutateur multiplexeur i2c
          <H4 id="i2c-ADC">i2c n-channel ADC ?</H4>
          <H4 id="i2c-key matrix">i2c key matrix reader</H4>
          example : HT16K33
          <H4 id="i2c-USB-host">i2c USB host ?</H4>
          
          <H3 id="attiny-controller">Create a full midi controller</H3>
          I'm not sure if it is possible to connect multiple I2C peripherals to a single attiny.
        <H2 id="hardware">Other hardware</H2>
          <H3 id="chips">Interesting chips</H3>
            Programming a PIC microcontroller seems a bit tricky, 
            you need a special board or a complex arduino shield.
            see also AT90USB1286 : 8bit MCU with usb host and device, but not in stock
            <H4 id="CYC63813PXC">CYC63813PXC</H4>
            Low Speed USB Peripheral Controller, ~5€
            <H4 id="MAX3421E">MAX3421E :</H4>
            USB Peripheral/Host Controller with SPI Interface
            <br />suggested by <a className="link-ul" href="https://docs.arduino.cc/retired/shields/arduino-usb-host-shield">this post</a>
            <br />Can act as a midi device or host, with SPI interface.
            <br />Advantages: 
            <ul>
              <li>Easier implementation ?</li>
              <li>Maybe cheaper than mcu with integrated usb host ?</li>
              <li>Compliance assured</li>
              <li>Free up MCU cycles</li>
            </ul>
            Drawbacks:
            <ul>
              <li>Expensive (min 10€)</li>
              <li>Maybe complex to run/program ?</li>
              <li>No PDIP package</li>
            </ul>
            <H4 id="ATmega32U4">ATmega32U4</H4>
            8-bit Microcontroller with 16/32K bytes of ISP Flash and USB Controller
            <br />suggested by <a className="link-ul" href="https://www.instructables.com/ATtiny85-IR-USB-Receiver/">this post</a>
            <br />see <a className="link-ul" href="https://ww1.microchip.com/downloads/aemDocuments/documents/MCU08/ProductDocuments/Brochures/AVR-Microcontrollers-Peripheral-Integration-30010135.pdf"
            >comparison chart</a> for avr MCUs.
            <br />Advantages: 
            <ul>
              <li>AVR instruction set</li>
              <li>USB compliance assured</li>
              <li>price ~5€</li>
              <li>can run low-speed crystal-less</li>
              <li>Probably programmable with arduino as SPI</li>
              <li>12 adc, with noise canceller</li>
              <li>26 GPIO</li>
              <li>16MHz max freq</li>
              <li>Atmel datasheet!</li>
              <li>has i2c despite what the comparison chart says</li>
            </ul>
            Drawbacks:
            <ul>
              <li>No PDIP package</li>
              <li>No USB host</li>
              <li>Must use crystal for full-speed, can run low-speed crystal-less</li>
              <li>Maybe too much pins for my applications</li>
              
            </ul>
            <H4 id="PIC16LF1459-E/P">PIC16LF1459-E/P</H4>
            8-Bit Flash USB Microcontroller with XLP Technology
            <br />found browsing atmel site
            <br />see <a className="link-ul" href="https://ww1.microchip.com/downloads/aemDocuments/documents/MCU08/ProductDocuments/Brochures/8-bit-PIC-Microcontroller-Peripheral-Integration-30010068.pdf"
            >comparison chart</a> for 8-bit PIC MCUs.
            <br />Advantages: 
            <ul>
              <li>USB compliance assured</li>
              <li>PDIP package</li>
              <li>price ~3€</li>
              <li>can run low-speed and full-speed crystal-less</li>
              <li>Probably programmable with arduino as SPI</li>
              <li>9 adc</li>
              <li>14 GPIO</li>
              <li>48 MHz max freq</li>
            </ul>
            Drawbacks:
            <ul>
              <li>non AVR instruction set</li>
              <li>No USB host</li>
              <li>No noise canceller on ADC</li>
              <li>Only 49 instructions</li>
              <li>Programming : <a className="link-ul" href="https://sdcc.sourceforge.net/">sdcc</a>, but PIC16 unmaintained</li>
              <li>see <a className="link-ul" href="https://stackoverflow.com/questions/16690635/does-gcc-support-pic-particularly-the-problematic-pic16-family"
              >this post for ideas on how to program it</a></li>
              <li>I'll probably have to use Microship IDE</li>
            </ul>
          <H3 id="usb-host">Implementing USB host</H3>
            <H4 id="usb_host_arm">ARM microcontroller</H4>
              One possibility is to resort to an arm microcontroller, but they are expensive and 
              I don't have a setup for it. 
              On an arduino Nano, the usb connectivity is obtained with an inexpensive ATSAMD11D14A chip (~1€)
              used as a peripheral of the main MCU. The ATSAMD11D14A is an arm M0 microcontroller 
              with integrated USB device peripheral.
              <br />Other chips of the SAM family are interesting. I didn't review them all. 
              The ATSAMD21 has a Host/device peripheral.
              <table>
                <tr>
                  <th>Family</th>
                  <th>Cortex</th>
                  <th>USB</th>
                  <th>type</th>
                  <th>Price (€)</th>
                </tr>
                <tr>
                  <td>SAM D21/DA1 </td>
                  <td>M0+</td>
                  <td>1</td>
                  <td>H/D, FS 2.0</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>SAM D5x/E5x </td>
                  <td>M4</td>
                  <td>1</td>
                  <td>H/D, FS 2.0</td>
                  <td>6</td>
                </tr>
                <tr>
                  <td>SAM D20 </td>
                  <td>M0+</td>
                  <td>0</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>SAM D11 </td>
                  <td>M0+</td>
                  <td>1</td>
                  <td>D, FS 2.0</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>SAM D10</td>
                  <td>M0+</td>
                  <td>0</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>SAM D09</td>
                  <td>M0+</td>
                  <td>0</td>
                  <td></td>
                  <td></td>
                </tr>
              </table> 
              
            <br />Maybe there are some i2c chips, but I did'nt find one.
            <br />Maybe some 8bit MCUs can do it, but I didn't find one.
            <br />A pi pico can be used as usb host, but it seems that the lib (tinyUSB) doesn't support midi usb.
            <a className="link-ul" href="https://github.com/rppicomidi/midi2usbhost">This fork of tinyUSB</a> 
            claims to support midi usb as host, to be tested.

            <br />There is an expensive arduino shield, with a {" "}
            <a className="link-ul" href="https://github.com/YuuichiAkagawa/USBH_MIDI">lib for midi usb</a>.

          <H3 id="midi_multiplexer">Midi multiplexer</H3>
          I think it can't run on an attiny. Here is a list of the features I'd like to see on such a device:
          <ul>
            <li>Can be powered by USB (or midi din ?: unlikely, midi IN is typically isolated from device) </li>
            <li>Can run without diving into menus or reprogramming, only push buttons</li>
            <li>Can also activate advanced features by diving into menus</li>
            <li>In normal mode, one input can be linked to a number of outputs, (or every input can be linked to every output ?)</li>
            <li>Hardware THRU for DIN and TRS</li>
            <li>Or maybe hard switchable hard/soft thru</li>
            <li>Button to switch trs type, programmable hard switches</li>
            <li>maybe trs and din can be hard connected (easier on the mcu)</li>
            <li>maybe CV, velo/mod and gate output</li>
          </ul>
          IO :
          <ul>
            <li>USB host</li>
            <li>USB peripheral</li>
            <li>DIN (in/out/thru)</li>
            <li>TRS (in/out/thru)</li>
            <li>CV, velo/mod and gate output</li>
          </ul>
          Controls:
          <ul>
            <li>momentary push for every IO except THRU</li>
            <li>led (or multicolored led ?) for every IO except THRU</li>
            <li>button to select trs (hard wiring) + led to indicate which type is each trs (including thru)</li>
            <li>LCD and rotary controller</li>
            <li>reset/recall button</li>
          </ul>
          Advanced features :
          <br />Accessible with lcd and rotary encoder with push.
          <ul>
            <li>Every input can be linked to every output (maybe do this in normal mode)</li>
            <li>Filter channels for every IO (except thru)</li>
            <li>Filter msg type for every IO (except thru)</li>
            <li>Filter specific messages for every IO</li>
            <li>Select source for every output with GUI</li>
          </ul>
          Operation: normal mode:
          <ul>
            <li>quick mode: short push to activate source (deselects any other source), short push to activate output</li>
            <li>parallel mode: long push to activate a source, short push to select outputs, then short push on source to validate selection</li>
            <li>maybe the connected IO could have the same LED color</li>
            <li>maybe blink leds when selecting in parallel mode</li>
          </ul>
          Operation: advanced mode:
          <ul>
            <li>list of setups</li>
            <li>inputs: filters</li>
            <li>thru: select soft/hard, filters</li>
            <li>outputs: select input, filters</li>
            <li>for trs out/thru/in : select type a/b</li>
            <li>for cv/gate/velo : select source, msg type, etc.; select range (nV/oct, etc.)</li>
          </ul>
          Documentation : 
          <ul>
            <li><a className="link-ul" href="http://www.interfacebus.com/PC_MIDI_Pinout.html">Midi pinout</a></li>
            <li><a className="link-ul" href="https://ccrma.stanford.edu/~gary/controllers/midi.html">Midi pinout & signal</a></li>
            <li><a className="link-ul" href="https://minimidi.world/">Midi TRS pinout</a></li>
          </ul>
          Dev roadmap:
          <ol>
            <li>Select chips</li>
            <li>test features & chips:</li>
            <ol>
              <li>MCU</li>
              <ul>
                <li>buy MCU and breakout board</li>
              </ul>
              <li>midi usb device</li>
              <li>midi din {"<->"} usb</li>
              <li>rotary</li>
              <li>lcd</li>
              <li>hard switches : CD74HC4066EE4 ? SN74LV4066AN ?</li>
              <li>usb host</li>
              <li>colored leds</li>
              <li>DAC for CV outputs</li>
            </ol>
          </ol>
        <H2 id="Soft">Software</H2>
          Finish scharm

      </>
    );
  }
}

const ProjectsTimeline:Article = {
  title:"Projects Timeline",
  abstract: "A page where I log my projects ideas and order them.",
  link:"/articles/2022/11/06/Projects_timeline",
  content: <Content/>,
  date:"2022:11:06:20:22",
}

export default ProjectsTimeline;