import {
  // Component, 
  // createRef, 
  // KeyboardEvent, 
  useState, useEffect} from "react"


const CollapsiblePart = (props:any)=>{ 
  const [showShow, setShowShow] = useState(Boolean);
  const toggleShow = () => setShowShow(!showShow);
  const toggleCollapse = ()=>{
    // console.log("toggling");
    toggleShow();
    
  }
  return(
    <span style={{fontSize:"smaller", transition:"height 300ms cubic-bezier(.4, 0, .2, 1)"}}>
      <span onClick={toggleCollapse}>{showShow ? "[hide]" : "[show]"}</span>
      <div className={"collapsePart " + (showShow?"":"collapsed")}>{props.children}</div>
    </span>);
}

export default CollapsiblePart;