import vusb_attiny45 from "./ap04_img/vusb_attiny45-tr.png"
import sample_pot from "./ap04_img/pot.jpg"
import attiny_adc from "./ap04_img/attiny_adc.png"
import hvp_schematics from "./ap04_img/HVP_sch.png"
import HVP_breadboard from "./ap04_img/HVP_breadboard.jpg"
import proto_bb_1_pot from "./ap04_img/proto_bb_1_pot.jpg"
import SPI_programming_sch from "./ap04_img/SPI_programming_sch.png"
import vusb_integration_sch from "./ap04_img/vusb_integration_sch.png"
import protoboard_proto from "./ap04_img/protoboard_proto.jpg"
// import mockup_v2_render_transp from "./ap04_img/mockup_v2_render_transp.png"
// import mockup_v2_render_transp_3 from "./ap04_img/mockup_v2_render_transp_thickness3.png"
// import mockup_v2_render_transp_4 from "./ap04_img/mockup_v2_render_transp_thickness4.png"
// import mockup_v2_render_transp_5 from "./ap04_img/mockup_v2_render_transp_thickness5.png"
import mockup_v2_render_transp_10 from "./ap04_img/mockup_v2_render_transp_thickness10.png"
// import mockup_v2_render_transp_15 from "./ap04_img/mockup_v2_render_transp_thickness15.png"
// import mockup_v2_render_transp_20 from "./ap04_img/mockup_v2_render_transp_thickness20.png"
import complete_circuit_pretty_colored from "./ap04_img/complete_circuit_pretty_colored.png"
import pcb_tht_complete_sch_colored from "./ap04_img/pcb_tht_complete_sch_colored.png"
import pcb_tht_complete_brd_corrected from "./ap04_img/pcb_tht_complete_brd_corrected.png"
import PCB_THT_board_bottom from "./ap04_img/PCB_THT_board_bottom.png"
import PCB_THT_board from "./ap04_img/PCB_THT_board.png"
import attiny_adc_channels_colored from "./ap04_img/attiny_adc_channels_colored.png"
import SPI_picture from "./ap04_img/SPI_picture.jpg"
import VUSB_picture from "./ap04_img/VUSB_picture.jpg"
import complete_picture from "./ap04_img/complete_picture.jpg"
import pcb_tht_gnd_complete_brd from "./ap04_img/pcb_tht_gnd_complete_brd.png"
import pcb_tht_gnd_with_plate_complete_brd from "./ap04_img/pcb_tht_gnd_with_plate_complete_brd.png"
import PCB_THT_GND_board from "./ap04_img/PCB_THT_GND_with_plate_board.png"
import PCB_THT_GND_board_bottom from "./ap04_img/PCB_THT_GND_with_plate_board_bottom.png"


import SyntaxHighlighter from 'react-syntax-highlighter';
import {vs2015} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {Component, createRef, KeyboardEvent, useState, useEffect} from "react"

import "./imagesViewer.css"
import "../../../../components/collapseTableOfContent.css"

import {ImagesViewer} from "../../../../components/ImagesViewer"

import clamp from "../../../../utils/clamp"
// import { threadId } from "worker_threads"
/**
 * TODO : 
 *  - justify 
 *  - increase difference btw title and abstract in articlesSummary
 */
 
// const images = [
//   proto_bb_1_pot, 
//   mockup_v2_render_transp_10,
//   vusb_attiny45, 
//   sample_pot, 
//   SPI_programming_sch,
//   SPI_picture, 
//   attiny_adc_channels_colored,
//   attiny_adc, 
//   vusb_integration_sch, 
//   VUSB_picture, 
//   hvp_schematics,  
//   HVP_breadboard,
//   complete_circuit_pretty_colored,  
//   complete_picture,
//   protoboard_proto,
//   pcb_tht_complete_sch_colored,
//   pcb_tht_complete_brd_corrected,
//   PCB_THT_board,
//   PCB_THT_board_bottom,

// ]

// const showUnfinishedParagraphs = true;
const showUnfinishedParagraphs = false;

const codeString = 
`#define LED 3

void setup() {
  // put your setup code here, to run once:
  pinMode(LED, OUTPUT);
}

void loop() {
  // put your main code here, to run repeatedly:
  digitalWrite(LED, HIGH);
  delay(500);
  digitalWrite(LED, LOW);
  delay(500);
};
`
const avr_dude_output_blink_ide = 
`Sketch uses 682 bytes (16%) of program storage space. 
Maximum is 4096 bytes.
Global variables use 9 bytes (3%) of dynamic memory, 
leaving 247 bytes for local variables. Maximum is 256 bytes.
`

const blink_in_c = 
`#include <avr/io.h>
#include <util/delay.h>

int main (void)
{
    // Set Data Direction to output on port B, pin 3:
    DDRB = 0b00001000;
    
    while (1) {
        // set PB3 high
        PORTB = 0b00001000;
        _delay_ms(200);
        // set PB3 low
        PORTB = 0b00000000;
        _delay_ms(500);
    }

    return 1;
}
`

const avr_dude_blink_in_c = 
`AVR Memory Usage
----------------
Device: attiny45

Program:      98 bytes (2.4% Full)
(.text + .data + .bootloader)

Data:          0 bytes (0.0% Full)
(.data + .bss + .noinit)
`

const blink_with_registers =
`#include <avr/delay.h>

void setup() {
  // set PB3 as output
  DDRB = (1<<PB3);
}

void loop() {
  // set PB3 high
  PORTB |= (1<<PB3);
  // waiting with the routine provided by the avr lib
  _delay_ms(200);
  // set PB3 low
  PORTB &= ~(1<<PB3);
  _delay_ms(500);
}
`

const avr_size_blink_with_registers =
`Sketch uses 324 bytes (7%) of program storage space. 
Maximum is 4096 bytes.
Global variables use 9 bytes (3%) of dynamic memory, 
leaving 247 bytes for local variables. Maximum is 256 bytes.
`
 
const low_noise_adc =
`// these headers are necessary for the following code ;
#include <avr/io.h>
#include <avr/interrupt.h>
#include <avr/sleep.h>

static void adcHardwareInit(void){
  // correspondence btw PORTB and ADC channels :
  //  PB5 -> ADC0
  //  PB4 -> ADC2
  //  PB3 -> ADC3
  //  PB2 -> ADC1
  
  // DDRB – Port B Data Direction Register
  // |   -   |   -   | DDB5  | DDB4  | DDB3  | DDB2  | DDB1  | DDB0  |
  DDRB &= ~(((_BV(PB4) | _BV(PB3)) | _BV(PB2)) | _BV(PB5));
  // unset PBn -> input
  
  /* **** prepare registers for ADC **** */
  
  // ADCSRB – ADC Control and Status Register B
  ADCSRB = 0; // Auto trigger and bipolar stuff
  
  
  // DIDR0 – Digital Input Disable Register 0
  // |   -   |   -   | ADC0D | ADC2D | ADC3D | ADC1D |   -   |   -   |
  DIDR0 = ((_BV(ADC2D) | _BV(ADC3D)) | _BV(ADC1D)) | _BV(ADC0D); 
  // disable digital input for all ADC channels
  // from the datasheet :
  //  When an analog signal is applied to the ADC[3:0] pin 
  //  and the digital input from this pin is not needed, 
  //  this bit should be written logic one 
  //  to reduce power consumption in the digital input buffer.
  
  
  // ADCSRA – ADC Control and Status Register A
  // | ADEN | ADSC | ADATE | ADIF | ADIE  | ADPS2  | ADPS1  | ADPS0  |
  // | ADC Enable | Start ADC | Auto trigger | Interrupt flag | Interrupt Enable | prescaler = clk/128 |;
  ADCSRA = _BV(ADEN) | _BV(ADIE) | 0x07 ; 
  // to use ADC noise reduction mode, ADIE must be set
}

char readPot(char adcChannel){
  // store the value of the ADC 
  char value = 0;

  // ADMUX – ADC Multiplexer Selection Register 0x07
  // | REFS1 | REFS0 | ADLAR | REFS2 | MUX3  | MUX2  | MUX1  | MUX0  |
  ADMUX = _BV(ADLAR) | adcChannel; 
  // bits 7, 6, 4 = 0 -> Vcc used as reference; 
  // bit 5 (ADLAR) = 1: results are left-adjusted;
  // bits[3:0] = 0000: ADC0 (PB5) is the input
  //           = 0001: ADC1 (PB2) is the input
  //           = 0010: ADC2 (PB4) is the input
  //           = 0011: ADC3 (PB3) is the input

  // enable interrupts
  sei();

  // MCUCR – MCU Control Register
  // | BODS  |  PUD  |  SE   |  SM1  |  SM0  | BODSE | ISC01 | ISC00 |
  MCUCR = (MCUCR & ~(_BV(SM1))) | (_BV(SM0) | _BV(SE));
  // set bit SM0, unset bit SM1: ADC noise reduction
  // set bit SE: sleep enable;
  // From the datasheet: 
  //  To avoid the MCU entering the sleep mode unless it is the programmer’s purpose, 
  //  it is recommended to write the Sleep Enable (SE) bit to one just before the execution
  //  of the SLEEP instruction and to clear it immediately after waking up.
  // entering sleep mode starts conversion in ADC noise reduction mode

  sleep_cpu(); //Sleep instruction to put controller to sleep
  // ADCL & ADCH contain result from adc conversion (resp. low and high byte)
  // since results are left-adjusted, we only need to read ADCH 
  // and shift it one place to get our value btw 0 and 127
  value = (ADCH >> 1);

  return value;
}

ISR(ADC_vect){
  // if we don't declare it, program counter goes to the reset vector (ie. the program resets)
  // if we declare it, PC goes back to the line after sleep instruction

  // clear the Sleep Enable (SE) bit immediately after waking up.
  MCUCR &= ~(1<<SE);
}
`


const usb_config_modification_for_not_INT0=
`/* ---------------------------- Hardware Config ---------------------------- */
#define USB_CFG_DMINUS_BIT      0 // selects PB0 as the pin used for D-
#define USB_CFG_DPLUS_BIT       1 // selects PB1 as the pin used for D+

/* ----------------------- Optional MCU Description ------------------------ */
#define USB_INTR_CFG            PCMSK // Pin Change Mask Register
#define USB_INTR_CFG_SET        (1<<PCINT1) // Pin Change 1 Enable Mask, bit in PCMSK
#define USB_INTR_CFG_CLR        0

#define USB_INTR_ENABLE         GIMSK // General Interrupt Mask Register
#define USB_INTR_ENABLE_BIT     PCIE  // Pin Change Interrupt Enable, bit in GIMSK
#define USB_INTR_PENDING        GIFR  // General Interrupt Flag Register
#define USB_INTR_PENDING_BIT    PCIF  // Pin Change Interrupt Flag, bit in GIFR
#define USB_INTR_VECTOR         PCINT0_vect // Interrupt vector Pin Change Interrupt Request 0 
// note: this is the interrupt vector for PCINT0:5
`
const hvp_output =
`Code is modified by Rik. Visit riktronics.wordpress.com and electronics-lab.com for more projects
-------------------------------------------------------------------------------------------------
Enter any character to start process..
Starting process...
Reading signature from connected ATtiny......
Reading complete..
Signature is: 9206
Reading fuse settings from connected ATtiny.......
LFuse: E1, HFuse: 5F, EFuse: FF
Reading complete..
The ATtiny is detected as 
ATTINY45..
Writing correct fuse settings to ATtiny.......
Writing complete..
Writing correct fuse settings to ATtiny.......
Writing complete..
Writing correct fuse settings to ATtiny.......
Writing complete..
Fuses will be read again to check if it's changed successfully..
Reading fuse settings from connected ATtiny.......
LFuse: 62, HFuse: DF, EFuse: FF
Reading complete..
`
const unstable_midi_output = 
`13:13:19.789  Ch  1, Ctrl  23, Val 127
13:13:19.797  Ch  1, Ctrl  22, Val   0
13:13:19.806  Ch  1, Ctrl  23, Val 124
13:13:19.822  Ch  1, Ctrl  23, Val 127
13:13:19.835  Ch  1, Ctrl  22, Val   1
13:13:19.846  Ch  1, Ctrl  22, Val   5
13:13:19.857  Ch  1, Ctrl  23, Val 126
13:13:19.865  Ch  1, Ctrl  22, Val   3
`

const usb_config_modification_for_midi =
`/* --------------------------- Functional Range ---------------------------- */

#define USB_CFG_HAVE_INTRIN_ENDPOINT    1
// Define this to 1 if you want to compile a version with two endpoints: The
// default control endpoint 0 and an interrupt-in endpoint (any other endpoint
// number).

#define USB_CFG_IMPLEMENT_HALT          1
// Define this to 1 if you also want to implement the ENDPOINT_HALT feature
// for endpoint 1 (interrupt endpoint). Although you may not need this feature,
// it is required by the standard. We have made it a config option because it
// bloats the code considerably.

#define USB_CFG_IMPLEMENT_FN_READ       1
// Set this to 1 if you need to send control replies which are generated
// "on the fly" when usbFunctionRead() is called. If you only want to send
// data from a static buffer, set it to 0 and return the data from
// usbFunctionSetup(). This saves a couple of bytes.


/* -------------------------- Device Description --------------------------- */

#define USB_CFG_DEVICE_CLASS        0	/* Defined at interface level */
#define USB_CFG_DEVICE_SUBCLASS     0	/* Defined at interface level */
// See USB specification if you want to conform to an existing device class.
 */
#define USB_CFG_INTERFACE_CLASS     1	/* AUDIO class */
#define USB_CFG_INTERFACE_SUBCLASS  3	/* MIDI streaming */
#define USB_CFG_INTERFACE_PROTOCOL  0	/*  */

#define USB_CFG_HID_REPORT_DESCRIPTOR_LENGTH    0	/* total length of report descriptor */
// Define this to the length of the HID report descriptor, if you implement
// an HID device. Otherwise don't define it or define it to 0.
// If you use this define, you must add a PROGMEM character array named
// "usbHidReportDescriptor" to your code which contains the report descriptor.
// Don't forget to keep the array and this define in sync!


/* ------------------- Fine Control over USB Descriptors ------------------- */

#define USB_CFG_DESCR_PROPS_DEVICE                  USB_PROP_IS_DYNAMIC
#define USB_CFG_DESCR_PROPS_CONFIGURATION           USB_PROP_IS_DYNAMIC
`

const usbFunctionDescriptor =
`uchar usbFunctionDescriptor(usbRequest_t * rq){

	if (rq->wValue.bytes[1] == USBDESCR_DEVICE) {
		usbMsgPtr = (uchar *) deviceDescrMIDI;
		return sizeof(deviceDescrMIDI);
	} else {		/* must be config descriptor */
		usbMsgPtr = (uchar *) configDescrMIDI;
		return sizeof(configDescrMIDI);
	}
}`

const usbDeviceAndConfigDescriptors = 
`// This descriptor is based on http://www.usb.org/developers/devclass_docs/midi10.pdf
// 
// Appendix B. Example: Simple MIDI Adapter (Informative)
// B.1 Device Descriptor
//
static PROGMEM char deviceDescrMIDI[] = {	/* USB device descriptor */
	18,			/* sizeof(usbDescriptorDevice): length of descriptor in bytes */
	USBDESCR_DEVICE,	/* descriptor type */
	0x10, 0x01,		/* USB version supported */
	0,			/* device class: defined at interface level */
	0,			/* subclass */
	0,			/* protocol */
	8,			/* max packet size */
	USB_CFG_VENDOR_ID,	/* 2 bytes */
	USB_CFG_DEVICE_ID,	/* 2 bytes */
	USB_CFG_DEVICE_VERSION,	/* 2 bytes */
	1,			/* manufacturer string index */
	2,			/* product string index */
	0,			/* serial number string index */
	1,			/* number of configurations */
};

// B.2 Configuration Descriptor
static PROGMEM char configDescrMIDI[] = {	/* USB configuration descriptor */
	9,			/* sizeof(usbDescrConfig): length of descriptor in bytes */
	USBDESCR_CONFIG,	/* descriptor type */
	101, 0,			/* total length of data returned (including inlined descriptors) */
	2,			/* number of interfaces in this configuration */
	1,			/* index of this configuration */
	0,			/* configuration name string index */
#if USB_CFG_IS_SELF_POWERED
	USBATTR_SELFPOWER,	/* attributes */
#else
	USBATTR_BUSPOWER,	/* attributes */
#endif
	USB_CFG_MAX_BUS_POWER / 2,	/* max USB current in 2mA units */

// B.3 AudioControl Interface Descriptors
// The AudioControl interface describes the device structure (audio function topology) 
// and is used to manipulate the Audio Controls. This device has no audio function 
// incorporated. However, the AudioControl interface is mandatory and therefore both 
// the standard AC interface descriptor and the classspecific AC interface descriptor 
// must be present. The class-specific AC interface descriptor only contains the header 
// descriptor.

// B.3.1 Standard AC Interface Descriptor
// The AudioControl interface has no dedicated endpoints associated with it. It uses the 
// default pipe (endpoint 0) for all communication purposes. Class-specific AudioControl 
// Requests are sent using the default pipe. There is no Status Interrupt endpoint provided.
	/* AC interface descriptor follows inline: */
	9,			/* sizeof(usbDescrInterface): length of descriptor in bytes */
	USBDESCR_INTERFACE,	/* descriptor type */
	0,			/* index of this interface */
	0,			/* alternate setting for this interface */
	0,			/* endpoints excl 0: number of endpoint descriptors to follow */
	1,			/* */
	1,			/* */
	0,			/* */
	0,			/* string index for interface */

// B.3.2 Class-specific AC Interface Descriptor
// The Class-specific AC interface descriptor is always headed by a Header descriptor 
// that contains general information about the AudioControl interface. It contains all 
// the pointers needed to describe the Audio Interface Collection, associated with the 
// described audio function. Only the Header descriptor is present in this device 
// because it does not contain any audio functionality as such.
	/* AC Class-Specific descriptor */
	9,			/* sizeof(usbDescrCDC_HeaderFn): length of descriptor in bytes */
	36,			/* descriptor type */
	1,			/* header functional descriptor */
	0x0, 0x01,		/* bcdADC */
	9, 0,			/* wTotalLength */
	1,			/* */
	1,			/* */

// B.4 MIDIStreaming Interface Descriptors

// B.4.1 Standard MS Interface Descriptor
	/* interface descriptor follows inline: */
	9,			/* length of descriptor in bytes */
	USBDESCR_INTERFACE,	/* descriptor type */
	1,			/* index of this interface */
	0,			/* alternate setting for this interface */
	2,			/* endpoints excl 0: number of endpoint descriptors to follow */
	1,			/* AUDIO */
	3,			/* MS */
	0,			/* unused */
	0,			/* string index for interface */

// B.4.2 Class-specific MS Interface Descriptor
	/* MS Class-Specific descriptor */
	7,			/* length of descriptor in bytes */
	36,			/* descriptor type */
	1,			/* header functional descriptor */
	0x0, 0x01,		/* bcdADC */
	65, 0,			/* wTotalLength */

// B.4.3 MIDI IN Jack Descriptor
	6,			/* bLength */
	36,			/* descriptor type */
	2,			/* MIDI_IN_JACK desc subtype */
	1,			/* EMBEDDED bJackType */
	1,			/* bJackID */
	0,			/* iJack */

	6,			/* bLength */
	36,			/* descriptor type */
	2,			/* MIDI_IN_JACK desc subtype */
	2,			/* EXTERNAL bJackType */
	2,			/* bJackID */
	0,			/* iJack */

//B.4.4 MIDI OUT Jack Descriptor
	9,			/* length of descriptor in bytes */
	36,			/* descriptor type */
	3,			/* MIDI_OUT_JACK descriptor */
	1,			/* EMBEDDED bJackType */
	3,			/* bJackID */
	1,			/* No of input pins */
	2,			/* BaSourceID */
	1,			/* BaSourcePin */
	0,			/* iJack */

	9,			/* bLength of descriptor in bytes */
	36,			/* bDescriptorType */
	3,			/* MIDI_OUT_JACK bDescriptorSubtype */
	2,			/* EXTERNAL bJackType */
	4,			/* bJackID */
	1,			/* bNrInputPins */
	1,			/* baSourceID (0) */
	1,			/* baSourcePin (0) */
	0,			/* iJack */


// B.5 Bulk OUT Endpoint Descriptors

//B.5.1 Standard Bulk OUT Endpoint Descriptor
	9,			/* bLenght */
	USBDESCR_ENDPOINT,	/* bDescriptorType = endpoint */
	0x1,			/* bEndpointAddress OUT endpoint number 1 */
	3,			/* bmAttributes: 2:Bulk, 3:Interrupt endpoint */
	8, 0,			/* wMaxPacketSize */
	10,			/* bIntervall in ms */
	0,			/* bRefresh */
	0,			/* bSyncAddress */

// B.5.2 Class-specific MS Bulk OUT Endpoint Descriptor
	5,			/* bLength of descriptor in bytes */
	37,			/* bDescriptorType */
	1,			/* bDescriptorSubtype */
	1,			/* bNumEmbMIDIJack  */
	1,			/* baAssocJackID (0) */


//B.6 Bulk IN Endpoint Descriptors

//B.6.1 Standard Bulk IN Endpoint Descriptor
	9,			/* bLenght */
	USBDESCR_ENDPOINT,	/* bDescriptorType = endpoint */
	0x81,			/* bEndpointAddress IN endpoint number 1 */
	3,			/* bmAttributes: 2: Bulk, 3: Interrupt endpoint */
	8, 0,			/* wMaxPacketSize */
	10,			/* bIntervall in ms */
	0,			/* bRefresh */
	0,			/* bSyncAddress */

// B.6.2 Class-specific MS Bulk IN Endpoint Descriptor
	5,			/* bLength of descriptor in bytes */
	37,			/* bDescriptorType */
	1,			/* bDescriptorSubtype */
	1,			/* bNumEmbMIDIJack (0) */
	3,			/* baAssocJackID (0) */
};`

const usbMidiCC = 
`if (usbInterruptIsReady()) {
  // read the value of the potentiometer
  // pot is a pointer to a struct 
  readValue(pot);
  
  // if the potentiometer has moved, we want to warn the host
  if(pot->changed){
    ccValue = value(pot);	

    midiMsg[0] = 0x0b; // the first byte is more or less equivalent to the second with the lower half first
    midiMsg[1] = 0xb0; // 0xb: Control change (CC) / 0x0: channel 0
    midiMsg[2] = 0x16; // CC number, here we have CC 22 (0x16 = 22) 
    midiMsg[3] = ccValue;  // the value of the control change

    // ask the v-usb driver to send the data on the next request
    usbSetInterrupt(midiMsg, 4);
  }else{
    midiMsg[0] = 0x00; 
    usbSetInterrupt(midiMsg, 1); // empty midi message
  }
}
`

const eeprom_fuse = 
`# ATtiny*5 FUSE_H (Fuse high byte):
# 0xd5 = 1 1 0 1   0 1 0 1
#        ^ ^ ^ ^   ^ \\-+-/ 
#        | | | |   |   +------ BODLEVEL 2..0 (brownout trigger level -> 101: 2.7V)
#        | | | |   +---------- EESAVE (preserve EEPROM on Chip Erase -> 0: preserved)
#        | | | +-------------- WDTON (watchdog timer always on -> 1: disable)
#        | | +---------------- SPIEN (enable serial programming -> 0: enabled)
#        | +------------------ DWEN (debug wire enable -> 1: disabled)
#        +-------------------- RSTDISBL (disable external reset -> 1: reset enabled)
`

const eemem_example = 
`#include <avr/eeprom.h>

// the cc array is allocated in the .eeprom section
uint8_t EEMEM cc[4] = {22, 23, 24, 25};

// we can then read it with:
uint8_t ccNumber =  eeprom_read_byte(&cc[i]);

// and set it with:
eeprom_update_byte(&cc[i], someValue);
`

const avrdude_write_to_eeprom = 
`# this is not the full makefile, but an excerpt with the relevant targets to build and write to the eeprom

DEVICE  = attiny45
F_CPU   = 16500000	# in Hz

PORT = /dev/ttyACM0 # edit if the arduino nano is connected to another port
AVRDUDE = avrdude -c stk500v1 -P $(PORT) -p $(DEVICE) -b19200

CFLAGS  = -Iusbdrv -I. -DDEBUG_LEVEL=0 -DDEBUG_LEVEL=0 -DTUNE_OSCCAL=1 -DCALIBRATE_OSCCAL=0
OBJECTS = usbdrv/usbdrv.o usbdrv/usbdrvasm.o usbdrv/oddebug.o main.o 

COMPILE = avr-gcc -Wall -Os -DF_CPU=$(F_CPU) $(CFLAGS) -mmcu=$(DEVICE)


# symbolic targets:
help:
	@echo "This Makefile has no default rule. Use one of the following:"
	@echo "make hex ....... to build main.hex"
	@echo "make flash ..... to build flash the firmware"
	@echo "make eeprom .... to write data in the EEPROM"

hex: main.hex

# rule for uploading firmware:
flash: main.hex
	$(AVRDUDE) -U flash:w:main.hex:i

# rule to write to eeprom
eeprom: main.hex
	$(AVRDUDE) -U eeprom:w:main.eep.hex:i

# Generic rule for compiling C files:
.c.o:
	echo $(COMPILE) -c $< -o $@
	$(COMPILE) -c $< -o $@

# Generic rule for assembling Assembler source files:
.S.o:
	$(COMPILE) -x assembler-with-cpp -c $< -o $@
# "-x assembler-with-cpp" should not be necessary since this is the default
# file type for the .S (with capital S) extension. However, upper case
# characters are not always preserved on Windows. To ensure WinAVR
# compatibility define the file type manually.

# Generic rule for compiling C to assembler, used for debugging only.
.c.s:
	$(COMPILE) -S $< -o $@

# file targets:

# Since we don't want to ship the driver multiple times, we copy it into this project:
usbdrv:
	cp -r ../../usbdrv .

main.elf: usbdrv $(OBJECTS)	# usbdrv dependency only needed because we copy it
	$(COMPILE) -o main.elf $(OBJECTS)

main.hex: main.elf
	rm -f main.hex main.eep.hex
# copy the program from main.elf to main.hex
	avr-objcopy -j .text -j .data -O ihex main.elf main.hex
# copy the .eeprom section of the .elf file in main.eep.hex, and set the start address at 0
# if we don't change the start address, it starts at 0x810000, (avr-gcc sets special sections at addresses >0x800000)
# which is well outside the address range of our MCU (and avrdude expects eeprom data to start at 0).
	avr-objcopy -j .eeprom --change-section-address .eeprom=0 -O ihex main.elf main.eep.hex
	avr-size -C --mcu=$(DEVICE) main.elf
`

const decode_midi_messages = 
`#include <avr/eeprom.h>

uint8_t EEMEM cc[4] = {22, 23, 24, 25};

void decodeMidiMsg(uchar * data, uint8_t start){
  // usb midi messages start with an extra byte that specifies the type of message 
  // and the number corresponding to the virtual cable associated with the endpoint
  if((data[start] & 0x0f) == 0x0b){ // ie. if it's a CC message

    // this is redundant with the previous check, but just to be sure :
    if((data[start + 1]& 0xf0) == 0xb0){ // b -> CC message

      // find which eeprom byte has the value data[start+2]
      for(int i=0; i<4; i++){
        if(eeprom_read_byte(&cc[i]) == data[start+2]){
          eeprom_update_byte(&cc[i], data[start + 3]);
          return; // if more than one pot has the same CC number, only the first one is updated
        }
      } 
    }
  }
  // usb midi message anatomy :
    // byte 0: virtual cable number / type
    // byte 1: type (CC = 0xb) / channel number
    // byte 2: CC number 
    // byte 3: CC value
}

void usbFunctionWriteOut(uchar * data, uchar len){
	// the midi in messages are sent in this function

	// decode midi message
  // most of the time, the host sends 4 bytes messages, 
  // but sometimes, it sends 8 bytes ones, we don't want to ignore them
  // the midi messages should not have any other length, if they do, we can safely ignore them
  if(len == 4){
    decodeMidiMsg(data, 0);
  }else if(len == 8){
    decodeMidiMsg(data, 0);
    decodeMidiMsg(data, 4);
  }

}
`

const githubLink = ""
// Note to self for future expansion :
// search "i2c 8 Channel CI commutateur multiplexeur" on mouser
//


interface BaseStates {
  showChild: boolean;
}



class ScrollToTop extends Component <{}, {}>{
  state = {
    opacity: this.computeOpacity()
    // opacity: "0"
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      window.onscroll = () => {
        
        let currentScrollPos = window.pageYOffset;
        // console.log(maxScroll)
        if (currentScrollPos > 0) {
          this.setState({ opacity: this.computeOpacity() })

        } else {
          this.setState({ opacity: "0" })
        }
      }
    }
  }
  computeOpacity(){
    if (typeof window !== "undefined") {
      let ramp = 0.5;
      let currentScrollPos = window.pageYOffset;
      let minScroll = window.innerHeight;
      return clamp((currentScrollPos - minScroll/3) * ramp / minScroll, 0, 1).toString()
    }else{
      return "1";
    }
  }
  render() {
    return (
      <div onClick={()=>{window.scrollTo({top: 0, left: 0, behavior: "smooth"});}} 
        className="scrollTop nonDrag" 
        draggable="false" 
        onDragStart={()=>{}}
        style={{ opacity: `${this.state.opacity}`}}
        />)
  }
}

class CodeBlock extends Component <any, {}>{
  // constructor(props:any){
  //   super(props);
  // }
  render(){
    let language = "c";
    if(this.props.language){
      language = this.props.language
    }
    return (
    <div style={{fontSize:"initial", width:"75vw"}}>
      <SyntaxHighlighter language={language} style={vs2015} showLineNumbers>
        {this.props.children}
      </SyntaxHighlighter>
    </div>
    );
  }
}

class InlineCode extends Component <any, {}>{
  // constructor(props:any){
  //   super(props);
  // }
  render() {
    return(<span style={{fontFamily: "monospace",fontSize:"smaller", ...this.props.style}} >{this.props.children}</span>);
  }
}

class InlineCommand extends Component <any, {}>{
  // constructor(props:any){
  //   super(props);
  // }
  render() {
    return(<InlineCode style={{backgroundColor:"black"}}>{this.props.children}</InlineCode>);
  }
}

class Citation extends Component <any, {}>{
  // constructor(props:any){
  //   super(props);
  // }
  render() {
    let sourceElt = <></>;
    let sourceText = "source"
    if(this.props.source){
      sourceText = this.props.source;
    }
    if(this.props.href){
      sourceElt = <>{" "}(<a className="link-ul" href={this.props.href}>{sourceText}</a>)</>;
    }else if(this.props.source){
      sourceElt = <>{" "}({sourceText})</>
    }
    return(<><q style={{fontStyle:"italic"}}>{this.props.children}</q>{sourceElt}</>);
  }
}

const useHeadingsData = () => {
  const [nestedHeadings, setNestedHeadings] = useState([Element]);

  useEffect(() => {
    const headingElements = Array.from(
      document.querySelectorAll("h4, h5")
    );

    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestedHeadings(newNestedHeadings);
  }, []);

  return { nestedHeadings };
};

const getNestedHeadings = (headingElements:Array<Element>) => {
  const nestedHeadings:Array<any> = [];

  headingElements.forEach((heading:any, index:number) => {
    const { innerText: title, id } = heading;

    if (heading.nodeName === "H4") {
      nestedHeadings.push({ id, title, items: [] });
    } else if (heading.nodeName === "H5" && nestedHeadings.length > 0) {
      nestedHeadings[nestedHeadings.length - 1].items.push({
        id,
        title,
      });
    }
  });

  return nestedHeadings;
};

const Headings = ({ headings }:any) => (
  <ul style={{marginBottom:"0px"}}>
    {headings.map((heading:any) => (
      <li key={heading.id}>
        <a className="link-nostyle" href={`#${heading.id}`}>{heading.title}</a>
        {heading.items.length > 0 && (          
          <ul>
            {heading.items.map((child:any) => (
              <li key={child.id}>
                <a className="link-nostyle" href={`#${child.id}`}>{child.title}</a>
              </li>            
            ))}
          </ul>
        )}
      </li>
    ))}
  </ul>
);

const TableOfContents = (props:any) => {
  const { nestedHeadings } = useHeadingsData();
  // console.log("nestedHeadings : ", nestedHeadings)
  if(nestedHeadings.length > 1){
    return (
      <nav aria-label="Table of contents" className={"toc-content " + props.collapseClassName}>
        <Headings headings={nestedHeadings} />    
      </nav>
    );
  }else{
    return(<></>);
  }
};

const CollapsibleTableOfContents = ()=>{ 
  
  // showShow:boolean=false;
  // setShowShow = (a:boolean)=>{};
  // toggleShow = ()=>{};
  
  // constructor(props:any){
  //   super(props);
  //   [this.showShow, this.setShowShow] = useState(Boolean);

  //   this.toggleShow = () => this.setShowShow(!this.showShow);
  // }
  const [showShow, setShowShow] = useState(Boolean);
  const toggleShow = () => setShowShow(!showShow);
  const toggleCollapse = ()=>{
    // console.log("toggling");
    toggleShow();
    
  }
  return(
    <div style={{fontSize:"smaller", transition:"height 300ms cubic-bezier(.4, 0, .2, 1)"}}>
      <br />
      <span onClick={toggleCollapse} >Table of contents {showShow ? "-" : "+"}</span>
      <TableOfContents collapseClassName={showShow?"":"collapsed"}/>
    </div>);
}

// class CollapsibleTableOfContents extends Component <any, {}>{ 
  
//   showShow:boolean=false;
//   setShowShow = (a:boolean)=>{};
//   toggleShow = ()=>{};
  
//   constructor(props:any){
//     super(props);
//     [this.showShow, this.setShowShow] = useState(Boolean);

//     this.toggleShow = () => this.setShowShow(!this.showShow);
//   }
//   toggleCollapse = ()=>{
//     console.log("toggling");
//     this.toggleShow();
//   }
//   render() {
//     return(
//       <div style={{fontSize:"smaller"}}>
//         <br />
//         <span onClick={this.toggleCollapse}>Table of contents +</span>
//         <TableOfContents/>
//       </div>);
//   }
// }

class Content extends Component <any, {}>{
  // constructor(props:any){
  //   super(props);
  // }
  // componentDidUpdate(){
  //   const scrollPosition = sessionStorage.getItem("scrollPosition");
  //   if(scrollPosition){ 
  //     setTimeout(function () {
  //       window.scrollTo(0, 3000);
  //       console.log("scrolled");
  //     },200);
  //     // window.scrollTo(0, parseInt(scrollPosition));
  //     console.log("App scrolling to ", scrollPosition);
  //     sessionStorage.removeItem("scrollPosition");
  //   }else{
  //     sessionStorage.setItem("scrollPosition", (window.scrollY).toString());
  //   }
  // }
  render() {
    return (
      <>
        <div style={{ backgroundImage:`url(${proto_bb_1_pot})`,backgroundRepeat:"no-repeat",
        backgroundSize: "cover",
        minHeight: "10em",
        // paddingLeft: "1em",
        backgroundBlendMode: "darken",
        backgroundColor: "#282c3499",
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        }}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            margin: "1em",
            
          }}>
            <h1 id="title" style={{marginTop: 0}}>AP-04</h1>
            A minimalist Midi-over-USB device with 4 potentiometers
            <br/>Driven by an ATtiny 45 running V-USB
          {/* <br/> */}
          </div>
        </div>
        <br />
        [NOTE] Writing in progress
        <CollapsibleTableOfContents/>
        <ScrollToTop/>
        {/* <br />
        TODO :
        <br /> Before launch :
        <ul>
          <li>Proper https</li>
        </ul>
        Maybe after launch :
        <ul>
          <li>solve all accessibility issues</li>
          <li>TOC should not be focusable when collapsed </li>
        </ul>
        <br />
        Done :
        <ul>
          <li>TOC</li>
          <li>back to top key</li>
          <li>add tags for the titles</li>
          <li>schematics</li>
          <li>
            photos
            <ul>
              <li> 4 pots on BB</li>
              <li> v-usb</li>
              <li> SPI</li>
              <li> sch with ADC highlighted</li>
            </ul>
          </li>
        </ul> */}

        <h4 id="motivation">Motivation</h4>
        <p>
          When I am developing various software synthesizers, I often need to change some filter cutoff frequency, 
          tune an oscillator or adjust the speed of a sequencer.
          And I don't always need a full Midi keyboard or a big collection of potentiometers, 
          rather a small device that can fit on a small desktop, with just a few pots and maybe a couple of buttons.
          I started designing a board that could meet my needs. 
          I thought it was a good opportunity to improve my knowledge on microcontrollers, prototyping and PCB design.
        </p>
        <h4 id="aim">Aim</h4>
          <ImagesViewer imagePath={mockup_v2_render_transp_10} alt="Mockup of the final card" 
          style={{width:'50%', marginTop:"0em"}}
          caption="Model of the final board, rendered with Blender"/>
        <p>
          I am developing a simple MIDI over USB device, with 4 potentiometers. 
          I want to have a small board with as few components as possible. 
          It has to be a class-compliant midi over usb device, as I want it to work without a specific driver.
          And I would like to have at least two momentary push buttons.
        </p>
        <h4 id="design-choices">Design choices</h4>
        <div>
          Inspired by <a className="link-ul" href="https://mitxela.com/projects">Mixtella</a>
          , I will use an ATtiny microcontroller to generate the midi over usb signals. 
          Specifically, I want to use the ATtiny85, as it is the most powerful one of the ATtiny range.
          <br/> The ATtiny is an AVR 8 bit microcontroller unit (MCU), with a maximum clock frequency of 20MHz. 
          <br/> I will not list every feature, but among what we are using:
          <ul>
            <li>4K of flash to hold the program*</li>
            <li>256 bytes of EEPROM and 256 bytes of SRAM*</li>
            <li>a 4-channel, 10-bit ADC</li>
            <li>6 general purpose I/O line</li>
            <li>an internal clock oscillator</li>
          <span style={{fontSize:"medium"}}>*for the ATtiny45, I will explain later why I chose this one.</span>
          </ul>
          So at first glance, I can use 2 I/O lines to drive the USB, and 4 ADC channels to read potentiometers values and buttons. 
          We will see later how we can achieve that.
          <br/>I could use a peripheral to read pots values, but both as a challenge and 
          to reduce the number of components on the board, I will try to make the board with an ATtiny as the only chip.
          <br/>The ATtiny range has a small footprint as they are 8 pins MCUs. They come in PDIP packages, 
          which is handy for development, as well as SOIC and QNF/MLF packages, which are useful for a smaller final version.
          <br/> Finally, this MCU is popular among hobbyists, and there are plenty of resources. 
          There are dedicated development boards, 
          but it is also possible to program it with an Arduino as ISP (stands for in-system programming/programmer), 
          which is what I will be doing.
          It uses the serial peripheral interface (SPI) of the ATtiny.
          <br/>
          The <a className="link-ul" href="https://www.obdev.at/products/vusb/index.html">V-USB</a> library 
          provides schematics to design a USB device with Atmel's AVR microcontrollers (such as the ATtiny85).
          <br/>
          <ImagesViewer alt="V-USB schematics for using USB with an ATtiny" imagePath={vusb_attiny45} style={{width:"70%"}}
          caption="Schematics provided by v-usb of the hardware needed to a midi device with an ATtiny"/>

          <br/>
          For the potentiometers, I want to use the same kind I used in a previous device, because they have a small footprint:
          <br/>
          <ImagesViewer imagePath={sample_pot} alt="an example of the kind of pot I want to use" style={{width:"20%", marginTop:"5vh"}}/>
          
          <br/>
          I want the final board to have an ISP header, to be able to improve the software easily.
          <br/>And maybe, if the MCU is powerful enough, an option to output native midi over trs signals, 
          to work with all hardware.
        </div>
        <h4 id="project-timeline">Project timeline</h4>
        <p>
          After spending quite some time reading various datasheets, I ordered the required parts.
          <br/>I got myself an Arduino Nano Every to use as an ISP, 
          and I had to order ATtiny45 instead of 85, because the 85 were out of stock.
          <br />Since the 45 has less memory than the 85, I thought it could cause a problem, but it seems to be enough.
        </p>
          <h5 id="parts">Parts</h5>
          <div style={{marginLeft:"2em", marginTop:"1em"}}>
            <h6 style={{margin:"0em"}}>SPI</h6>
            <ul style={{marginTop:"0em"}}>
              <li>Arduino Nano Every</li>
              <li>10 µF electrolytic capacitor</li>
            </ul>
            <h6 style={{margin:"0em"}}>USB interface</h6>
            <ul style={{marginTop:"0em"}}>
              <li>USB mini B socket</li>
              <li>3V6 Zener diodes (2)</li>
              <li>68 Ohm 1/4 W ceramic resistors(2)</li>
              <li>1.5 kOhm 1/4 W ceramic resistor</li>
              <li>4.7 µF electrolytic capacitor</li>
              <li>100 nF ceramic capacitor</li>
            </ul>
            <h6 style={{margin:"0em"}}>Main hardware</h6>
            <ul style={{marginTop:"0em"}}>
              <li>ATtiny45-20PU (PDIP package)</li>
              <li>10 kOhm linear rotary potentiometers (4) (ref: RK09D1130C2P)</li>
            </ul>
          </div>
          [Note] All parts are through-hole (THT)
        <h5 id="programming-the-attiny">Programming the ATtiny</h5>
        <p>
          There are plenty of detailed examples on the net on how to program an ATtiny with an Arduino as ISP 
          (eg. <a className="link-ul" href="https://create.arduino.cc/projecthub/arjun/programming-attiny85-with-arduino-uno-afb829">here</a>), 
          so I will simply provide a schematic and a picture of my setup.
          <br/>
          <ImagesViewer imagePath={SPI_programming_sch} alt="Schematics to use an Arduino nano every as a programmer for the ATtiny 45" 
          style={{width:'60%', marginTop:"1em"}}
          caption="Arduino nano every as a programmer for the ATtiny 45."/>

          <br/>
          Usually a 10µF capacitor is added between the RESET and GND pins of the arduino, but it also works without it.
          <ImagesViewer imagePath={SPI_picture} alt="Setup for SPI on a breadboard" 
          style={{width:'60%', marginTop:"1em"}}
          caption="Programming the ATtiny with an arduino nano as ISP"/>

          <br/>
          <br/>
          [Note] I am not using Arduino IDE to develop the firmware, 
          so I use a modified version of the Makefile provided by the v-usb lib to compile and upload to the MCU. 
          The modified Makefile can be found in my github <a className="link-ul" href={githubLink}>repo</a>.
          <br/> I use <a className="link-ul" href="https://github.com/npat-efault/picocom">picocom</a> as
          a serial monitor: <InlineCommand>picocom -b BAUDRATE PORT</InlineCommand>.
        </p>
        <h5 id="first-programs">First programs</h5>
        <p>
          I programmed a sketch with the Arduino IDE to run on the ATtiny45. 
          It reads the value of a single pot and sends its value to the nano every via a custom two-wire serial interface. 
          The value is then displayed on the serial monitor of the arduino IDE.
          This serial interface is used as a debugging tool, to check if the values read are stable and if the ATtiny is working.
          But I was already getting near the program memory limit of the MCU, 
          so it was impossible to add the USB library and to read the values of 3 other pots.
          <br/>After a bit of tinkering, I tried programming the MCU directly in C with avrdude and ditching the Arduino IDE. 
          To check if I was going in the right direction, I tried compiling the most simple blink sketch with the Arduino IDE:

          <div style={{fontSize:"initial", width:"75vw"}}>
            <SyntaxHighlighter language="Arduino" style={vs2015} showLineNumbers>
              {codeString}
            </SyntaxHighlighter>
          </div>

          And the output of avr-size for the ATtiny45 is:
          <div style={{fontSize:"initial", width:"75vw"}}>
            <SyntaxHighlighter language="plaintext" style={vs2015}>
              {avr_dude_output_blink_ide}
            </SyntaxHighlighter>
          </div>

          To compare, here is a simple blink program in C:
          <div style={{fontSize:"initial", width:"75vw"}}>
            <SyntaxHighlighter language="c" style={vs2015} showLineNumbers>
              {blink_in_c}
            </SyntaxHighlighter>
          </div>

          And the output of avr-size for the ATtiny45 is:
          <div style={{fontSize:"initial", width:"75vw"}} >
            <SyntaxHighlighter language="plaintext" style={vs2015}>
              {avr_dude_blink_in_c}
            </SyntaxHighlighter>
          </div>

          I tried disassembling the file compiled by the arduino IDE, and noticed it has a separate subroutine handling delays, 
          whereas the delay compiled from C is handled in the main loop.
          The waiting subroutine in the disassembled arduino version is 52 lines long 
          whereas the waiting logic in the disassembled C version is only 9 lines long.
          <br/>
          More impressive is the digitalWrite subroutine in the disassembled arduino version: 
          54 lines where the C version takes only one line!
          <br/>So I am not sure why the arduino version needs to be so heavy, 
          there is probably a good reason for it and maybe there is a way to optimise the build, 
          but as it is simpler to directly code in C with the text editor of my choice, I will go this route now.
          While with arduino, reading a unique pot and sending its value via the serial interface to the nano take 76% 
          of program memory of the ATtiny, 
          in C it takes only 17% with my latest version.
          <br/> 
          <br />[EDIT] The comparison above is not really fair: I remembered that you can also do register 
          manipulation on Arduino and reduce the size of digitalWrite. 
          <br/> Also I was using <InlineCode>sprintf</InlineCode> which is arguably an overkill. 
          I ended up creating custom functions to write numeric values in 
          a <InlineCode>char</InlineCode> buffer.
          Remain the mysteries of the delay subroutine, and of what is done in the digitalWrite sub.
          <br />
          A better comparison would be with this program, which only uses half as much program memory as the previous arduino program :
          <div style={{fontSize:"initial", width:"75vw"}}>
            <SyntaxHighlighter language="c" style={vs2015} showLineNumbers>
              {blink_with_registers}
            </SyntaxHighlighter>
          </div>
          <div style={{fontSize:"initial", width:"75vw"}}>
            <SyntaxHighlighter language="plaintext" style={vs2015}>
              {avr_size_blink_with_registers}
            </SyntaxHighlighter>
          </div>
          It is still a lot more than the code compiled directly with avrdude.
          <br />From what I understand, it is mainly initialization of registers and timers, 
          and it may be only a small overhead.

        </p>
        <h5 id="adc-noise-canceller">Reading analog values with the ATtiny: ADC noise canceler</h5>

        <ImagesViewer alt="Schematics of an ATtiny with the ADC channels highlighted" 
        imagePath={attiny_adc_channels_colored} style={{width:"50%"}}
        showCaption={true}/>

        <br/>
        <p>
        The ATtiny can perform analog to digital conversion (ADC) on any pin with an ADC channel (with a "ADCn" label).
        In order to read precisely the values of the potentiometers, 
        I am using the noise canceler ADC of the ATtiny, as specified in the datasheet.
        The conversion starts when the MCU is put in sleep mode, and it wakes up once the conversion is finished.
        <br/> Here is the code I used to read analog values:
        <div style={{fontSize:"initial", width:"75vw"}}>
          <SyntaxHighlighter language="c" style={vs2015} showLineNumbers>
            {low_noise_adc}
          </SyntaxHighlighter>
        </div>
        This mode may not be necessary in the final board which could be less noisy than a breadboard,  
        but if it doesn't create problems with the usb timings I will keep it. 
        It is really important to have stable values, otherwise using the device will be quite frustrating.
        <br />This code was made following the datasheet registers description, 
        I was not able to find a library doing this operation in the <a className="link-ul" href="https://www.nongnu.org/avr-libc/">AVR Libc</a> project.
        <br />[Note] As recommended by the avr libc project,
        I use the <InlineCode>_BV()</InlineCode> macro (bit value), 
        which performs the bit shift (<InlineCode>_BV(PB3)</InlineCode> is 
        equivalent to <InlineCode>1{"<<"}PB3</InlineCode>) 
        and is optimized to a set bit or clear bit assembly instruction if appropriate.
        </p>
        <h5 id="reading-multiple-pots">Reading multiple pots</h5>
        <p>
        After trying my code with only one potentiometer, I added a second one and came across a new bug. 
        The values were unstable, especially when they were far apart.
        Below is an example of the reading with two potentiometers. 
        The first one (Ctrl  22) should be at 0, and the second one (Ctrl 23) at 127.
        <div style={{fontSize:"initial", width:"75vw"}} >
          <SyntaxHighlighter language="plaintext" style={vs2015}>
            {unstable_midi_output}
          </SyntaxHighlighter>
        </div>
        It took me longer than I will admit to debug this one, 
        but after checking every register I could think of and creating a single-wire debug interface, 
        I managed to understand why it was behaving like that.
        <br/>The input circuitry of the ATtiny for the ADC is presented below:
        <br/>
        <ImagesViewer alt="Atmel's schematics for the analog-to-digital conversion circuitry inside an ATtiny" 
        imagePath={attiny_adc} style={{width:"50%"}}
        showCaption={true}/>
        <br/>
        And from the datasheet: <Citation>When the channel is selected, 
        the source must drive the S/H capacitor through the series resistance (combined resistance in the input path).</Citation>
        <br/> From what I understand, when we are reading different pots
        (ie. applying rapidly changing voltages), 
        the S/H capacitor doesn't have enough time to stabilize and give a consistent value.
        
        <br/> The workaround I found is to repeat the ADC multiple times and only use the last value. Repeating twice is enough 
        to have a stable value.
        <br/> Of course, this workaround is slowing down my code. I later tested it with midi over USB and four pots 
        with alternating values and didn't notice any timing or stability issues. 
        <br/>
        <br/>
        [EDIT] I found out that I was using the wrong clock prescaler for the ADC, which is an even dumber bug. 
        The datasheet recommends using a clock frequency lower than 1MHz and I was using the system clock at 16.5 MHz divided by 8, 
        so about 2MHz.
        The prescaler is set in the ADCSRA register. I tried the slowest clock and it was stable enough with only one reading. 
        Lower prescalers (ie. faster clocks) were not stable for my breadboard prototype. 
        I will try to adjust the clock in the PCB prototype, and check which method is the fastest.
        </p>
        <h5 id="vusb">Integrating V-USB</h5>
        <p>
        <ImagesViewer alt="Schematics of the circuit needed to connect the ATtiny via usb using the V-USB library" 
        imagePath={vusb_integration_sch} style={{width:'60%', marginTop:"1em"}}
        caption="Circuitry needed to use V-USB"/>

        <br/> As detailed later, I am not using the pins recommended by v-usb on the ATtiny 
        (in order to free up the ADC channels). 
        USB delivers 5V on the VCC pin, but it is required that the D+ and D- lines have a maximum voltage of 3.3V. 
        The V-USB lib provides several designs to accomplish that. One solution is to power the MCU at 3.3V, 
        but the ATtiny can't be clocked at 16 MHz (which is necessary, see below) with a supply voltage below 4.5V 
        (the maximum clock rate is 10MHz for a supply voltage below 4.5V). So I chose an other solution, 
        which is to limit the output voltage on D+ and D- with 3V6 Zener diodes.
        <br/>
        [Note] <a className="link-ul" href="http://sarangan.org/electronics/2020/06/27/v-usb-on-attiny45-85-microcontrollers/">
          Someone
        </a> tried the different designs provided by v-usb, as well as different internal clock calibrations 
        and came to the conclusion that the design with Zener diodes was the less reliable one, 
        but so far it worked perfectly for me, even without touching the factory calibration of the clock.
        <ImagesViewer alt="ATtiny with v-usb on a breadboard" 
        imagePath={VUSB_picture} style={{width:'60%', marginTop:"1em"}}
        caption="Circuitry needed to use V-USB, on a breadboard"/>
        
        <br/> 
        <h5 id="timings">Timing</h5>
        The timing for v-usb is very important, and using a crystal oscillator is recommended. 
        But it would take at least a pin on the ATtiny, and we don't want that, we'd rather use an internal clock.
        The ATtiny has a few internal clocks, notably an internal crystal oscillator, but it is too slow @128kHz. 
        <br />V-usb can operate at 12 MHz, 12.8 MHz, 15 MHz, 16 MHz, 16.5 MHz, 18 MHz and 20 MHz, but {" "}
        <Citation href="http://vusb.wikidot.com/hardware">only the 16.5 MHz and 12.8 MHz variants allow a 
        deviation of up to 1%</Citation>.
        We will use the High Frequency PLL Clock @16.5MHz, which (simplification here) doubles the internal 8MHz RC oscillator's frequency.
        The PLL can be calibrated to run @16.5MHz.
        <br />
        According to its datasheet, the PLL is supposed to provide a 16MHz clock with a factory calibration of the internal RC oscillator.
        But from what I measured, the the frequency is closer to 16.5MHz, which is perfect for me, I don't need to change the calibration.
        <br /> However, the frequency may vary with temperature, and v-usb provides {" "}
        <a className="link-ul" href="http://vusb.wikidot.com/examples#toc4">a way to calibrate the oscillator automatically using usb timings</a>.
        <br/> To test my setup, I uploaded one of the examples provided with the library, and it worked without any problem. 
        I tried the HID mouse example, and it worked immediately on different computers.
        </p>
        <h5 id="int0">Not using INT0 for usb</h5>
        <p>
        The v-usb library uses two pins of the ATtiny for USB communication. 
        By default, D+ on the USB side is connected to hardware interrupt INT0 
        <Citation source="from the v-usb documentation">because this is the highest priority interrupt on AVRs</Citation>.
        But on an ATtiny, the INT0 pin is also one of the ADC pins (ADC1), so in order to read 4 potentiometers, 
        I need to connect D- and D+ to the two pins that are not ADC channels, ie. PB0 and PB1. 
        I had to modify the following lines in usbconfig.h:
        <div style={{fontSize:"initial", width:"75vw"}}>
          <SyntaxHighlighter language="c" style={vs2015} showLineNumbers showInlineLineNumbers>
            {usb_config_modification_for_not_INT0}
          </SyntaxHighlighter>
        </div>
        After testing, it works flawlessly.
        </p>

        <h5 id="reset">Bricking and un-bricking the ATtiny</h5>
        <p>
        Another issue is that on an ATtiny, the pin 1 
        (PB5/ADC0/<span style={{textDecorationLine: "overline"}}>RESET</span>) is used as a reset pin and the first channel of the ADC. 
        Whenever the pin is set to less than approximately 1/3 of V<span style={{verticalAlign:"sub"}}>cc</span>, the MCU resets.
        It means that I can't use it to read a potentiometer's value as long as this pin is used for reset.
        <br/>
        This functionality can be disabled, but it also disables the SPI programming (in technical language, it "bricks" the device). 
        It means that once the reset functionality is disable, I can't program the MCU anymore, 
        unless I un-brick it with a high voltage programmer (HVP). 
        <br />DISABLING RESET : CODE
        <br />
        I followed the instructions 
        listed <a className="link-ul" href="https://www.electronics-lab.com/recover-bricked-attiny-using-arduino-as-high-voltage-programmer/"> 
        here</a>.
        I only changed one thing: I didn't have enough 1K resistors so I used 1.5K for R4 and R5.
        <br/>
        <ImagesViewer alt="High voltage programmer schematics using an Arduino" 
        imagePath={hvp_schematics} style={{width:"75vw"}}/>
        <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
          High voltage programmer schematics using an Arduino (
          <a className="link-ul" href="https://www.electronics-lab.com/recover-bricked-attiny-using-arduino-as-high-voltage-programmer/">
            source</a>)</div>
        <br/>
        BAT1 is a 12V source, I used a simple mains to DC power adapter that was laying around, 
        and an Arduino Mega to have a pin header access to the +12V.
        <br/>
        <ImagesViewer alt="High-voltage programmer on a breadboard" 
        imagePath={HVP_breadboard} style={{width:"50%"}}
        caption="Ignoring the left half of the breadboard, this is the setup I used to un-brick the ATtiny"/>

        <br/>
          <CodeBlock language="plaintext">
            {hvp_output}
          </CodeBlock>
        <div style={{textAlign:"center", fontSize:"smaller", textDecoration:"underline"}}>Output of a successful un-bricking</div>
        <br/> This methods works for me, but it means that on the final board, 
        I can't modify the software unless I have a way to apply 12V to the reset pin, 
        which is not possible with a potentiometer connected to the pin and soldered to the 5V and ground lines.
        {/* <br/> SCHEMATICS of the situation on the reset pin */}
        <br/> So I will have to design a solder bridge on the final pcb or find a clever trick.
        </p>
        <h5 id="midi">Adding midi</h5>
        I found an implementation of Midi over Usb using v-usb <a className="link-ul" 
        href="http://cryptomys.de/horo/V-USB-MIDI/index.html"> here</a>.
          <br />Following this example, I made these changes to <InlineCode>usbconfig.h</InlineCode> :
          <CodeBlock>
            {usb_config_modification_for_midi}
          </CodeBlock>
          <br /> <InlineCode>USB_PROP_IS_DYNAMIC</InlineCode> means that we have to describe it at runtime. 
          V-usb then expects us to define <InlineCode>usbFunctionDescriptor()</InlineCode> that 
          will provide successively the device and the config descriptions.
          <CodeBlock>
            {usbFunctionDescriptor}
          </CodeBlock>
          I copied both the device and the config descriptors from the midi implementation example:
          <CodeBlock>
            {usbDeviceAndConfigDescriptors}
          </CodeBlock>

          I don't understand everything here: it seems to me that we should define Bulk 
          endpoints rather than interrupt endpoints. I will test that later.
          I will simply note here that we can control the rate at which information are send with the config descriptor. 
          I left the default value of 10ms because it was quick enough for me.
          <br />To send Midi messages, we emulate a streaming device with a bulk-in endpoint (?). 
          But usb is controlled by the host, so we have to wait for the host to request data before we can send it. 
          (see <a className="link-ul" href="http://vusb.wikidot.com/driver-api#toc4">here</a> for details). 
          We check if <InlineCode>usbInterruptIsReady()</InlineCode> and if so, 
          we give <InlineCode>usbSetInterrupt()</InlineCode> a <InlineCode>char</InlineCode> buffer with the midi message.
          If we don't want to send any message, we can provide a <InlineCode>char</InlineCode> buffer with only one value at zero.
          There may be other ways to send empty data or no data, but from what I recall, 
          if we don't respond with any data when the host asks, the device disconnects or is not recognized by the host.
          <CodeBlock>
            {usbMidiCC}
          </CodeBlock>
          The first byte of the midi message specific to the midi over usb specification: the first half byte (or nibble) 
          corresponds to the number of the virtual midi jack associated with the endpoint, 
          the second nibble is a Code Index Number (CIN) which describes the type of midi message (see {" "}
          <a className="link-ul" href="https://www.usb.org/document-library/usb-midi-devices-10">the official documentation</a>, §4).
          The second byte is the start of the actual midi message (description <a className="link-ul" href="https://www.midi.org/specifications-old/item/table-1-summary-of-midi-message">here</a>).
          
        
        <h5 id="four-pots">4 Four pots on a breadboard</h5>
          <ImagesViewer alt="Complete schematics with a 8 pin header to allow both SPI and HVP, and a jumper to disconnect the pot linked to RESET" imagePath={complete_circuit_pretty_colored}
          style={{width:"70%"}}/>
          <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
            Complete schematics with a 8 pin header to allow both SPI and HVP, and a jumper to disconnect the pot linked to <span style={{textDecorationLine: "overline"}}>RESET</span>
          </div>
          <br />
          The jumper is the simplest way I found to disconnect the pot: I will solder two male pin headers, 
          and when the device is in normal mode, bridge them with a jumper. When I want to apply 12v (ie. switch to HVP mode), I simply remove the jumper.
          I could also use a dip switch. Here is a picture of the circuit on a breadboard:
          <ImagesViewer alt="Complete circuit on a breadboard" 
          imagePath={complete_picture} style={{width:"50%"}}
          caption="Complete circuit, with four potentiometers"/>
          {/* <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
            Complete circuit, with four potentiometers
          </div> */}

        <h5>First soldered prototype</h5>
          <br/>
          <ImagesViewer alt="First soldered prototype on a dot protoboard" 
          imagePath={protoboard_proto} style={{width:"50%"}}
          caption="At the time, the software for the fourth potentiometer was not ready, so I only soldered 3"/>

          <br />
          Not much to say here, it was a pain to solder but it works. 
          I am not using a standard SPI socket because I did not have a double row pin header.
          By mistake, I inverted the position of Vcc and

        <h5 id="control-change">Programming CCs</h5>
          I want to be able to reassign the midi control change (CC) assigned to each pot without reprogramming the MCU. 
          
          <br /> To achieve that, I need to store the CC numbers in a non volatile memory, ie. in the EEPROM of the ATtiny
          (for electrically erasable programmable read-only memory).

          Besides, I need to create new usb endpoints and functions to achieve that. Actually, as I already have 
          a Midi in endpoint, 
          I can use it to change CC values. The main downside is that I must be careful not to route 
          the midi messages from another controller to this one. But with the protocol I have set up, it should be fine (see below).
          I may write a small program to change the cc numbers with a GUI.
          <h6 id="using-eeprom">Using the EEPROM</h6>
            Using the EEPROM requires some precautions, 
            mainly protecting the MCU from low-voltage operations that can corrupt the EEPROM. 
            For that, the datasheet recommends
            enabling the Brown-out Detector (BOD). Basically, it puts the MCU in reset mode while the input voltage is 
            lower than a certain level, selected with fuses. Also, for convenience, 
            I disable the fuse that erases the EEPROM each time the MCU is reprogrammed.
            <CodeBlock language="bash">
              {eeprom_fuse}
              </CodeBlock>
            <br /> This may not be really necessary because the usb voltage supply is required to be between 4.75 and 5.25V 
            (see <a className="link-ul" href="http://esd.cs.ucr.edu/webres/usb11.pdf">usb11.pdf</a>, §7.3.2).
          <h6>Writing and reading EEPROM</h6>
            The EEPROM has a limited number of write cycles, but a supposedly unlimited number of read cycles.
            So, before writing to the EEPROM, the best practice is to check the value and only write 
            if the value we want to set is different from the current one. The avr-libc project gives a set of functions to do that, 
            specifically, we will use the <InlineCode>eeprom_update_byte (uint8_t *__p, uint8_t __value)</InlineCode> function, 
            where <InlineCode>__p</InlineCode> is a pointer to the byte in EEPROM we want to set. Although it should be easy enough 
            to do in the small firmware we are writing, it is not very practical to have to set manually addresses in EEPROM and remember them.
            
            <br />The avr-libc eeprom lib gives the <InlineCode>EEMEM</InlineCode> macro that allocates a variable in the EEPROM section.
            <CodeBlock>
              {eemem_example}
            </CodeBlock>
            <br /> In order to set initial values for the CC numbers, we have to write to the eeprom 
            when programming the MCU. I am not sure this is the right way, but I edited my make file to allow that:
            <CodeBlock language="bash">
              {avrdude_write_to_eeprom}
            </CodeBlock>

          <h6>Protocol</h6>
            When sending CC midi messages, we send CC number and value.
            The protocol I devised is simply to send the current value of the CC we want to change as the CC number 
            and the CC number we want to set as the value.
            As an example, a CC midi message looks like this :
            <CodeBlock>
              {`0xb0 // type of message (b: CC message) / channel number
0x16 // CC number = 22 (0x16)
0x42 // CC value = 66
              `}
            </CodeBlock>
            To assign CC number 32 to a potentiometer that has a CC number of 22, we would send:
            <CodeBlock>
              {`0xb0 // type of message (b: CC message) / channel number
0x16 // CC number = 22 (0x16)
0x20 // CC value = 32
              `}
            </CodeBlock>
            This should not cause too much issues as we typically don't have several CC that have the same number in a setup.
            <br /> The midi in messages (ie. send by the host to the controller) 
            are caught in the <InlineCode>usbFunctionWriteOut</InlineCode> v-usb function, 
            so we will decode the midi in messages in this function.
            <CodeBlock>
              {decode_midi_messages}  
            </CodeBlock>
        <h5 id="pcb-design">PCB Design</h5>
          <h6 id="pcb-design-first-version">First version</h6>
          I designed the dev PCB with through-hole components (THT), because I am more used to them, 
          but the final goal is to have a SMD PCB. I also want to test HVP on the board before committing to a SMD design.
          <br /> While I can always remove a socketed THT MCU from a board to program it, I can't do it with a soldered SMD MCU.
          <br /> The solution with a jumper is acceptable for a THT board, but for a SMD one, I think I will use a solder bridge.
          
          <ImagesViewer alt="Schematics of the THT board" 
          imagePath={pcb_tht_complete_sch_colored} style={{width:"70%"}}
          showCaption={true} caption="Schematics for the THT PCB"/>          

          <br />
          I added a LED for looks and to test SMD soldering. I am not sure that this is the right way, 
          but from what I understand, it is better to solder all shields to a common ground. (see {" "}
          <a className="link-ul" href="https://electronics.stackexchange.com/questions/389972/usb-shield-to-ground-or-not-to-ground">
            here</a>)
          <br /> Here is a view of the PCB, rendered in EAGLE:
          <ImagesViewer alt="PCB view, rendered in EAGLE" 
          imagePath={pcb_tht_complete_brd_corrected} style={{width:"50%"}}
          showCaption={true} caption="PCB, THT version"/>
          {/* <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
            PCB, THT version
          </div> */}

          <ImagesViewer alt="PCB view, top side" 
          imagePath={PCB_THT_board} style={{width:"50%"}}
          showCaption={true} caption="PCB, top view"/>
          {/* <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
            PCB, top view
          </div> */}

          <ImagesViewer alt="PCB view, bottom side" 
          imagePath={PCB_THT_board_bottom} style={{width:"50%"}}
          showCaption={true} caption="PCB, bottom view"/>
          
          {/* <div style={{textAlign:"center",fontSize:"smaller",textDecoration:"underline"}}>
            PCB, bottom view
          </div> */}

          I still don't know much about PCB design, that's why the traces' widths are all over the place, 
          and there is no ground plate.

          <br />
          [Note on versioning] The dev version have a major version number of 0, the version 1 will be the first satisfying smd PCB.
          <br />All the versions before the first THT PCB are 0.0.x . The first THT PCB is version 0.1.0. 
          <br />The first smd PCB will be version 0.2.0.

          <h6>Second version with VCC and GND plates</h6>
          [Hint] With Eagle, the ground plate is not shown when re-opening the file, click on "Rastnet" to show it.
          <ImagesViewer alt="PCB 0.1.1 rendered in EAGLE, GND and VCC plates not shown" 
          imagePath={pcb_tht_gnd_complete_brd} style={{width:"50%"}}
          caption="PCB 0.1.1 (THT + GND plate) view, rendered in EAGLE. The VCC and GND plates are not shown here for readability"/>
          <ImagesViewer alt="PCB 0.1.1 rendered in EAGLE, GND and VCC shown" 
          imagePath={pcb_tht_gnd_with_plate_complete_brd} style={{width:"50%"}}
          caption="PCB 0.1.1 (THT + GND plate) view, rendered in EAGLE."/> 


          <ImagesViewer alt="PCB 0.1.1 top view, rendered in EAGLE" 
          imagePath={PCB_THT_GND_board} style={{width:"50%"}}
          caption="PCB 0.1.1 (THT + GND plate) top view, rendered in EAGLE"/> 

          <ImagesViewer alt="PCB 0.1.1 bottom view, rendered in EAGLE" 
          imagePath={PCB_THT_GND_board_bottom} style={{width:"50%"}} 
          caption="PCB 0.1.1 (THT + GND plate) bottom view, rendered in EAGLE"/> 

        {showUnfinishedParagraphs ?
          <> 
          {/* if we display the article in progress */}
          
          <h5>First PCB</h5>
          PICTURE of populated board
          <h5>Surface-mount PCB</h5>
          solder bridge for rst
          <h5>Code</h5>
          Highlights
          <br/>Link to the github <a className="link-ul" href={githubLink}>repo</a>
          <h4>Conclusion</h4>
          <h5>Goals</h5>
          class compliance? not really
          <br /> Does it work with windows ?
          <br /> Does it work with Axoloti ?
          <br /> Buttons ?
          <br /> native midi ?
          <br /> response time ?
          <h5>[Self-satisfaction paragraph]</h5>
          <h5>Improvements</h5>
          proper i2c peripheral
          </>
          :
          <> 
          {/* for the public version */}
          
          <h5 id="next-steps">Next steps</h5>
          <ul> 
            <li>Redesign the PCB with a ground plate [Done]</li>
            <li>Order the PCB from JLCPCB</li>
            <li>Order the parts to populate the PCB</li>
            <li>Design SMD PCB</li>
            <li>Design enclosure ? 3D printed enclosure ?</li>
          </ul>
          </>
        }
        
        
      </>
    )
  }
}

// console.log(SyntaxHighlighter.supportedLanguages)

const AP04:Article = {
  title:"AP-04",
  abstract: "A minimalist Midi USB device with 4 potentiometers",
  link:"/articles/2021/11/20/AP-04",
  content: <Content/>,
  date:"2021:11:20:14:19",
}

export default AP04;
