import React from "react";

import ArticleList from "./articles/articlesList";
import "./Articles.css"

class Articles extends React.Component <{}>{
  componentDidUpdate(){
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if(scrollPosition){
      window.scrollTo(0, parseInt(scrollPosition));
      console.log("Article scrolling to ", scrollPosition);
      sessionStorage.removeItem("scrollPosition");
    }else{
      sessionStorage.setItem("scrollPosition", (window.scrollY).toString());
    }
  }

  render(){
    var article!:Article;
    // var title = ""
    var path = window.location.pathname;
    ArticleList.forEach(element => {
      if (element.link === path){
        article = element;
        // title = element.title;
      }
    });
    if(article !== undefined){

      return (
        <div className="articleContent" >
          {/* <h1>{article.title}</h1> */}
          <div>{article.content}</div>
        </div>
      );
    }else{
      return (<div  className="single-line-content">article not found</div>);
    }
  }
}

export default Articles;